<div class="row mt-5" style="display:flex">
    <div class="col-sm labOutter" >
        <div class="col-md-12 padding labTitle flex" >
            <div class="col-md-10">X-Ray.pdf</div>
            <div class="col-md-2"><img src="/assets/img/tick.png" /></div>
        </div>
        <div class="col-md-12 mt-3 labTestSub">Received on: 27 May 2024</div>
        <div class="col-md-12 mt-3 labTestSub">Not Reviewed</div>
        <div class="col-md-12 mt-4  " >
            <div class="col-md-12 float"><button class="btn btn1">View List</button></div>
            <div class="col-md-12 float mt-2"><button class="btn btn2">Download File</button></div>
             
        </div>
    </div>
    <div class="col-sm labOutter ml-2" >
        <div class="col-md-12 padding labTitle flex">
            <div class="col-md-10">X-Ray.pdf</div>
            <div class="col-md-2"><img src="/assets/img/tick.png" /></div>
        </div>
        <div class="col-md-12 mt-3 labTestSub">Received on: 27 May 2024</div>
        <div class="col-md-12 mt-3 labTestSub">Not Reviewed</div>
        <div class="col-md-12 mt-4  " >
            <div class="col-md-12 float"><button class="btn btn1">View List</button></div>
            <div class="col-md-12 float mt-2"><button class="btn btn2">Download File</button></div>
             
        </div>
    </div>
    <div class="col-sm labOutter ml-2 " >
        <div class="col-md-12 padding labTitle flex">
            <div class="col-md-10">X-Ray.pdf</div>
            <div class="col-md-2"><img src="/assets/img/tick.png" /></div>
        </div>
        <div class="col-md-12 mt-3 labTestSub">Received on: 27 May 2024</div>
        <div class="col-md-12 mt-3 labTestSub">Not Reviewed</div>
        <div class="col-md-12 mt-4  " >
            <div class="col-md-12 float"><button class="btn btn1">View List</button></div>
            <div class="col-md-12 float mt-2"><button class="btn btn2">Download File</button></div>
             
        </div>
    </div>
    <div class="col-sm labOutter ml-2 " >
        <div class="col-md-12 padding labTitle flex">
            <div class="col-md-10">X-Ray.pdf</div>
            <div class="col-md-2"><img src="/assets/img/tick.png" /></div>
        </div>
        <div class="col-md-12 mt-3 labTestSub">Received on: 27 May 2024</div>
        <div class="col-md-12 mt-3 labTestSub">Not Reviewed</div>
        <div class="col-md-12 mt-4  " >
            <div class="col-md-12 float"><button class="btn btn1">View List</button></div>
            <div class="col-md-12 float mt-2"><button class="btn btn2">Download File</button></div>
             
        </div>
    </div>
</div>