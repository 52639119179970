import { Component } from '@angular/core';

@Component({
  selector: 'app-common-image',
  standalone: true,
  imports: [],
  templateUrl: './common-image.component.html',
  styleUrl: './common-image.component.css'
})
export class CommonImageComponent {

}
