<div class="row flex fontfamily" >
    <div class="col-md-6 ">
        <app-common-image></app-common-image>
    </div>
    <div class="col-md-5 ml-3 marginTop">
        <div class="col-md-12 welcomeMsg">
           Reset Password
        </div>

        <div class="col-md-12 mt-2 subTitle">
            Enter new password
        </div>

        <div class="col-md-12 mt-5">
            <form #loginForm="ngForm" (ngSubmit)="changePassword()">
                <div class="mb-3 mt-4">
                  <label for="exampleInputEmail1" class="form-label labelReport">Enter Password</label>

                  <input matInput type="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" placeholder="Enter Password" class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
                 
                </div>
                <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label labelReport">Enter Confirm Password</label>
  
                    <input matInput type="password" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}" placeholder="Enter Confirm Password" class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
                   
                  </div>
       
                <button type="submit" class="btn btn-primary btn mt-4">Save</button>
              </form>
        </div>
       
    </div>
</div>