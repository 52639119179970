<div *ngIf="getData(eventData) < 3">
</div>
<div class="row notifyDiv mt-3" *ngIf="this.checkRecord < 1">
    No any upcoming meeting! 
</div>
<div class="row notifyDiv mt-3" *ngIf="this.checkRecord >0">
        
    <div class="col-sm-1 notifyDate">{{this.checkRecord}}/{{eventData.length}}</div>
    <div class="col-sm-3">
        <div class="col-md-12 title">{{event.title}}</div>
        <div class="col-md-12 mt-1 smallContent" >{{event.eventDescription}}</div>
    </div>
    <div class="joinDt">
        <div class="col-md-12 title">Date</div>
        <div class="col-md-12 mt-1 smallContent" >{{event.eventDate.split('T')[0]}}</div>
    </div>
    <div class="joinDt1">
        <div class="col-md-12 title">Time</div>
        <div class="col-md-12 mt-1 smallContent"> {{getTime(event.eventTime)}}</div>
    </div>
    <div class="joinDt2">
        <div class="col-md-12 title">Name</div>
        <div class="col-md-12 mt-1 smallContent">{{event.personName}}</div>
    </div>
    <div class="joinDt3 flex">
        <div class="col-md-8 floatLeft" ><a href="{{event.meetingLink}}" target="_blank"><img class="iconSize" src="/assets/img/firstJoin.png"/> </a></div>
        
    </div>
    <!-- <div class="joinDt3 notifyDate1 joinBtn" style="float:right;text-align:center ">
        <img class="iconSize" src="/assets/img/firstJoin.png"/> 
    </div>
    <div class="col delete" style="float:right;text-align:center;margin-left:10px;">
        
    </div> -->
    
  </div>

