
<mat-card class="col memberPartern ml-4" data-ride="carousel">
    
    <mat-card-header>
      
      <app-member-basic-detail [memberbasic]="memberdata" [screentype] = "1" ></app-member-basic-detail>
      
      <!-- <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
      <mat-card-title class="ml-3">{{memberdata.member}}</mat-card-title>
      <mat-card-subtitle class="ml-3">Age: {{memberdata.age}}</mat-card-subtitle> -->
    </mat-card-header>
     
    <mat-card-content>,
       <app-blood-pressure [memberbasic]="memberdata"></app-blood-pressure>
    </mat-card-content>
    <mat-card-actions class="mt-3  ">
        <!-- <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/video.png" /></a>
      
        <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/msg.png" /></a> -->
        <a  mat-button (click)="openDialog()" class="btnMember" mat-button>Member Advocate</a> &nbsp; &nbsp;
        <a mat-button [routerLink]="['/member-dashboard']" [queryParams]="{ id:memberdata.id}"  class="memberDashboard" mat-button>Member Dashboard</a>    &nbsp; &nbsp;
        <a mat-button [routerLink]="['/member-dashboard']" class="btnResolve" mat-button>Resolve</a>    
        
    </mat-card-actions>
  
  </mat-card>
  