import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import { ResultComponent } from '../result/result.component';
import {MatListModule} from '@angular/material/list';


@Component({
  selector: 'app-all-meals',
  standalone: true,
  imports: [BasicDetailsComponent,ResultComponent,MatListModule],
  templateUrl: './all-meals.component.html',
  styleUrl: './all-meals.component.css'
})
export class AllMealsComponent {

}
