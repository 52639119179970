import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MemberBasicDetailComponent } from '../../member/member-card/member-basic-detail/member-basic-detail.component';
import { NgForOf } from '@angular/common';
import { Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-meeting',
  standalone: true,
  imports: [MatCardModule,MemberBasicDetailComponent,NgForOf],
  templateUrl: './meeting.component.html',
  styleUrl: './meeting.component.css'
})
export class MeetingComponent {
  @Input() meeting:any;
  constructor(private datePipe: DatePipe){

  }
  getTime(selectedTime:any){
    const timeParts = selectedTime.split(':');
    const selectedDate = new Date();
    selectedDate.setHours(parseInt(timeParts[0], 10));
    selectedDate.setMinutes(parseInt(timeParts[1], 10));
   return this.datePipe.transform(selectedDate, 'hh:mm a');
  }

  getDate(selectedDate:any){
   
    // const selectedDate = new Date();
    // selectedDate.setHours(parseInt(timeParts[0], 10));
    // selectedDate.setMinutes(parseInt(timeParts[1], 10));
   return this.datePipe.transform(selectedDate, 'd MMM Y');
  }
//   memberList:any=[
//     {
//       'title':'Test',
//       'subTitle':'26',
//       'blood':'40',
//       'glucose':'60',
//       'meet':'https:google.com'
//   },
//   {
//     'title':'Test 2',
//       'subTitle':'26',
//       'blood':'40',
//       'glucose':'60',
//       'meet':'https:google.com'
//   }
// ];
}
