
<div class="title mt-2">Stress Test</div>
<div class="subTitle mt-2">For each questions choose from the  following alternatives:</div>

<div class="col-md-12 mt-5">
    <div class="col-md-12">
        <div class="question">1. In the last month, how often you felt that you were on top of things?</div>
    </div>

    <div class="col-md-12 mt-3">
     <app-question-options></app-question-options>
    </div>
</div>


<div class="title mt-2">Anxiety Test (GAD-7)</div>
<div class="subTitle mt-2">Over the last two weeks, how often have you been bothered by the following problems?:</div>

<div class="col-md-12 mt-5" >
    <div class="col-md-6 float">
        <div class="col-md-12">
            <div class="question">1. Feeling nervous, anxious or on edge</div>
        </div>
    
        <div class="col-md-12 mt-3">
         <app-question-options></app-question-options>
        </div>
    </div>

    <div class="col-md-6 float">
        <div class="col-md-12">
            <div class="question">2. Becoming easily annoyed or irritable?</div>
        </div>
    
        <div class="col-md-12 mt-3">
         <app-question-options></app-question-options>
        </div>
    </div>
   
</div>

<div class="title mt-4">Enneagram Test</div>
<app-enagram-test></app-enagram-test>
