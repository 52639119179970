import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';

@Component({
  selector: 'app-question-options',
  standalone: true,
  imports: [MatListModule],
  templateUrl: './question-options.component.html',
  styleUrl: './question-options.component.css'
})
export class QuestionOptionsComponent {

}
