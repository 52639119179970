import { Component } from '@angular/core';

@Component({
  selector: 'app-dietary-preference',
  standalone: true,
  imports: [],
  templateUrl: './dietary-preference.component.html',
  styleUrl: './dietary-preference.component.css'
})
export class DietaryPreferenceComponent {

}
