<div class="row flex fontfamily" >
    <div class="col-md-6 ">
        <app-common-image></app-common-image>
    </div>
    <div class="col-md-5 ml-3 marginTop">
        <div class="col-md-12 welcomeMsg">
           Forgot Password
        </div>

        <div class="col-md-12 mt-2 subTitle">
            Enter your email address and get link over email id
        </div>

        <div class="col-md-12 mt-4">
            <form #loginForm="ngForm" (ngSubmit)="forgetPassword()">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label labelReport">Email Address</label>

                  <input matInput type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="Enter Email Address" class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
                 
                </div>
                <a  [routerLink]="['/']"  class=" remember" for="exampleCheck1" style="float:right">Login</a>
                
                <button type="submit" class="btn btn-primary btn mt-4">Next</button>

              </form>
        </div>
       
    </div>
</div>