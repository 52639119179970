import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { Input

 } from '@angular/core';
 import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-member-team-count',
  standalone: true,
  imports: [MatCardModule,MatButtonModule,RouterLink],
  templateUrl: './member-team-count.component.html',
  styleUrl: './member-team-count.component.css'
})
export class MemberTeamCountComponent {

  @Input() totalCount:any;
}
