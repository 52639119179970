<div id="content-page" class="content-page">
    <div class="container-fluid">
        <div class="row">
           
              
                 <div class="col-md-12 col-lg-6">
                    <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div class="iq-card-body">
                                <div class="iq-progress-bar progress-bar-vertical iq-bg-primary">
                                        <span class="bg-primary" data-percent="70"></span>
                                    </div>
                                <span class="line-height-4 mainTitle">Good morning Dr. {{ this.userDetails.firstName}}</span>
                                
                            </div>

                          
                        </div>
                     </div>
        </div>

     

        <app-upcoming-meeting [eventData]="this.events"></app-upcoming-meeting>

          <div class="row">
            <div class="col-lg-12 cricticalRisk" style="margin-top:60px">
                     Crictical Risk
             </div>
             <div class="col-lg-12 mt-3 ">
                <span class="cricical1">{{this.memberList.length}}</span> &nbsp; <span class="criticalSub">Patients need immediate attention</span>
             </div>
             
         </div>
         <div class="row flex">
            <div class="col-12 text-right" *ngIf="this.memberList.length > 0">
                <a class="btn arrow mb-3"
                   href="#carouselExampleIndicators2"
                   role="button"
                   data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a> &nbsp;
                <a class="btn arrow mb-3 mr-1" 
                   href="#carouselExampleIndicators2"
                   role="button"
                    data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                
              </div>
              <div  id="carouselExampleIndicators2" class="carousel slide"
              data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active" *ngFor='let member of members;let i=index;' [ngClass]="{'active': i <1}" >                 

                    <app-critical-patients  [memberdata]="member" class="fullWidth"></app-critical-patients>
            
                </div>

            <!-- <div class="carousel-item">
               

                <app-critical-patients class="fullWidth"></app-critical-patients>
        
            </div> -->

            
            </div>
        </div>
         </div>
         
         <app-member-statistics [eventData]="this.events"></app-member-statistics>
         <!-- <app-lab-test></app-lab-test> -->
    </div>
             
            
    </div>

    