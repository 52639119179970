<div class="col-md-12 flex mt-5">
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri1.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Calories Percentage  {{nutri.calories.percentage}}%</div>
                <hr style="background:#1EA247;border:2px solid"> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall calories taken: {{nutri.calories.average}} Kcal</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{nutri.calories.goal}} Kcal</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri2.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Protein Percentage &nbsp; {{nutri.protein.percentage}}%</div>
                <hr style="background:#D02985;border:2px solid">
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall calories taken: {{nutri.protein.percentage}} g</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{nutri.protein.percentage}} g</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri3.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Carbs Percentage &nbsp;  {{nutri.carbs.percentage}}%</div>
                <hr style="background:#CEAD00;border:2px solid"> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall calories taken: {{nutri.carbs.percentage}} g</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal:  {{nutri.carbs.percentage}} g</div>
            </div>
        </div>
    </div>
    </div>