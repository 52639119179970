<div class="row">
    <img style="width:6%" src="assets/img/backbtn.png" />
</div>
<app-basic-details [screentype] = "2"></app-basic-details>

<div class="row mt-5 ml-2">
    <div class="title">All Meal Plans</div>
</div>

<div class="row mt-4 flex" style="padding: 0;">
    <div class="col">
    <mat-list role="list" class="flex">
        <mat-list-item class="col-md-3"  role="listitem" >
            <div class="active tab">
                <div class="tabContent">Ongoing Meal Plan</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-4"  role="listitem">
            <div class=" tab">
                <div class="tabContent">Completed Meal Plan</div>
            </div>
        </mat-list-item>
      
        
      </mat-list>
    </div>

    <div class="col-3 float-right textAlign mt-2">
        
         <input matInput type="date" placeholder="Search Date Range" class="form-control textField" />
    </div>
</div>

<div class="row mt-4">
    <div class="subTitle">Change meal time</div>
</div>

<div class="row mt-4">
    <div class="col-sm">
        <input type="text" class="form-control textFld textContent" placeholder="Breakfast"/>
    </div>
    <div class="col-sm">
        <input type="text" class="form-control textFld textContent" placeholder="Snack 1"/>
    </div>
    <div class="col-sm">
        <input type="text" class="form-control textFld textContent" placeholder="Lunch"/>
    </div>
    <div class="col-sm">
        <input type="text" class="form-control textFld textContent" placeholder="Snack 2"/>
    </div>
    <div class="col-sm">
        <input type="text" class="form-control textFld textContent" placeholder="Dinner"/>
    </div>
    <div class="col-sm">
        <input type="text" class="form-control textFld textContent" placeholder="Snack 3"/>
    </div>
</div>

<div class="row mt-5">
    <div class="col-sm">
        <app-result></app-result>
    </div>

    <div class="col-sm">
        <app-result></app-result>
    </div>
</div>
