import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-all-logs',
  standalone: true,
  imports: [MatListModule,RouterLink,NgIf],
  templateUrl: './all-logs.component.html',
  styleUrl: './all-logs.component.css'
})
export class AllLogsComponent {
 
  @Input() foodAllInfo:any;
  @Input() member:any;
  
  constructor(private datePipe: DatePipe){

  }
  getDate(selectedTime:any){
    const timeParts = selectedTime.split(':');
    const selectedDate = new Date();
    selectedDate.setHours(parseInt(timeParts[0], 10));
    selectedDate.setMinutes(parseInt(timeParts[1], 10));
   return this.datePipe.transform(selectedDate, 'hh:mm a');
  }
}
