<div class="row mt-3">
    <div class="col-md-1 mt-2"><img class="bloodIcon" src="assets/img/blood-pressure 1.png" /></div>
    <div class="col-md-8">
        <div class="col-md-12">
            <div class="titleBlood">Blood Pressure</div>
        </div>
        <div class="col-md-12">
            <div class="bloodPressure">{{memberbasic.blood}}</div>
        </div>
    </div>
    <div class="col-md-2"><img class="bloodIcon" src="assets/img/Up.png" /></div>
</div>

<div class="row mt-3">
    <div class="col-md-1 mt-2"><img class="bloodIcon" src="assets/img/Blood Glucose.png" /></div>
    <div class="col-md-8">
        <div class="col-md-12">
            <div class="titleBlood">Blood Glucose</div>
        </div>
        <div class="col-md-12">
            <div class="bloodPressure">{{memberbasic.glucose}}</div>
        </div>
    </div>
    <div class="col-md-2"><img class="bloodIcon" src="assets/img/Up.png" /></div>
</div>