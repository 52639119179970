<div class="col-md-12 mt-2">
    <div class="mainDiv">
       
        <div class="subject mt-5">Subjective</div>
        <div class="col-md-12">
            <div class="label mt-4">Chief Complaint:</div>
            <textarea class="textArea mt-4" placeholder="Type Here...."></textarea>
        </div>
        <div class="col-md-12 mt-5">
            <div class="label">Symptoms:</div>
            <textarea class="textArea mt-4" placeholder="Type Here...."></textarea>
        </div>
        <div class="col-md-12 mt-5">
           
           <div class="mt-4">
            <button class="btn btnVal">Save</button>
           
           </div>
        </div>
    </div>
</div>
