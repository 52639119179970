import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-blood-pressure',
  standalone: true,
  imports: [],
  templateUrl: './blood-pressure.component.html',
  styleUrl: './blood-pressure.component.css'
})
export class BloodPressureComponent {
  @Input() memberbasic:any;
}
