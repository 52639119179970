import { Component } from '@angular/core';

import { NotesComponent } from '../life-style/notes/notes.component';

@Component({
  selector: 'app-sleep',
  standalone: true,
  imports: [NotesComponent],
  templateUrl: './sleep.component.html',
  styleUrl: './sleep.component.css'
})
export class SleepComponent {

}
