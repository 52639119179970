import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-member-info',
  standalone: true,
  imports: [],
  templateUrl: './member-info.component.html',
  styleUrl: './member-info.component.css'
})
export class MemberInfoComponent {
  @Input() memberbasic:any;
  @Input() screentype:any;
  @Input() vital:any;
}
