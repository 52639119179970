import { Component } from '@angular/core';

@Component({
  selector: 'app-lab-reports',
  standalone: true,
  imports: [],
  templateUrl: './lab-reports.component.html',
  styleUrl: './lab-reports.component.css'
})
export class LabReportsComponent {

}
