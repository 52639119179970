import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControllerApiPath } from '../../services/constant';
import { SharedServiceService } from '../../services/shared-service.service';
import { Updatefoodlogs } from '../../models/updatefoodlogs';

@Injectable({
  providedIn: 'root'
})
export class FoodService {

  private apiBaseUrl:string;

  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }

   getFoodLogs(memberId:any,fromDate:any,toDate:any){
   
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.foodlog+'?memberId='+memberId+'&fromDate='+fromDate+'&toDate='+toDate;    
    return this.http.get(url,{ headers });
   }

   getFoodById(id:any){
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.foodlog+'/'+id;    
    return this.http.get<Updatefoodlogs>(url,{ headers }).pipe(
      map((todo: Updatefoodlogs) => {
        return todo;
      }));
      
   }

   updateFoodLog(data:any){
    const headers = this.sharedService.createAuthorizationHeader();
    return  this.http.put<Updatefoodlogs>(this.apiBaseUrl + ControllerApiPath.foodlog, data, { headers })
   }
}
