import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import { ResultComponent } from '../result/result.component';

@Component({
  selector: 'app-result-meal-plan',
  standalone: true,
  imports: [BasicDetailsComponent,ResultComponent],
  templateUrl: './result-meal-plan.component.html',
  styleUrl: './result-meal-plan.component.css'
})
export class ResultMealPlanComponent {

}
