
<form >
   
    <div class="col-lg-6 form-group float ">
       
            <label for="exampleInputEmail1" class="form-label labelReport">Enter First Name:</label>
      
            <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
           
     
    </div>
    <div class="col-lg-6 form-group float">
        
            <label for="exampleInputEmail1" class="form-label labelReport">Enter Last Name:</label>
      
            <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
           
 
    </div>
    
    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Enter Mobile Number:</label>
  
        <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
       
 
    </div>
    <div class="col-lg-6 form-group float mt-4">
        
            <label for="exampleInputEmail1" class="form-label labelReport">Enter Email Address:</label>
    
            <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
        

    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Street Address 1:</label>
  
        <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
       
 
    </div>
    <div class="col-lg-6 form-group float mt-4">
        
            <label for="exampleInputEmail1" class="form-label labelReport">Street Address 2:</label>
    
            <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
        

    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Country:</label>
  
        <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
       
 
    </div>
    <div class="col-lg-6 form-group float mt-4">
        
            <label for="exampleInputEmail1" class="form-label labelReport">State:</label>
    
            <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
        

    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Town/City:</label>
  
        <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
       
 
    </div>
    <div class="col-lg-6 form-group float mt-4">
        
            <label for="exampleInputEmail1" class="form-label labelReport">Zip Code:</label>
    
            <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
        

    </div>
 
    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Preferred Language:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Martial Status:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>
   

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport">Do you have children?:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport"> How many children’s do you have?:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>
    
    <div class="col-lg-6 form-group float mt-4">
        
        <label for="exampleInputEmail1" class="form-label labelReport">Date of Birth:</label>

        <input matInput type="email" placeholder="Type Here..." class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
    

    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport"> Please mention your qualification details:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport"> Height:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>

    <div class="col-lg-6 form-group float mt-4">
       
        <label for="exampleInputEmail1" class="form-label labelReport"> Unit of Height:</label>
        <select matSelectewaz class="form-control inputField">
            <option value="">Select Here</option>

        </select>
    </div>

    <button type="submit" class="btn btn-primary btn mt-4 color1">Back</button> &nbsp; <button type="submit" class="btn btn-primary btn mt-4 color">Save</button>
  </form>