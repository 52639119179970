<div class="row">
    <img style="width:6%" src="assets/img/backbtn.png" />
</div>
<app-basic-details [screentype] = "2"></app-basic-details>

<hr class="mt-5">

<div class="row mt-5">
   <div class="col">
    <div class="title">Daily Target Calories</div>
    <div class="subTitle mt-4">Check the values on the fields</div>
    <div class="flex mt-5 rightBorder">
        <input type="text" class="form-control textField textContent" placeholder="TDEE"/> &nbsp;&nbsp; <input type="text" placeholder="Calorie Deficit Goal" class="form-control textField textContent"/>
    </div>
   </div> 
   <div class="col">
    <div class="title">Meal Allocations</div>
    <div class="subTitle mt-4">Set the start and end date of the meal plan</div>
    <div class="flex mt-5 rightBorder">
        <input type="date" class="col-md-6 form-control textField textContent" placeholder="Select Date Range"/> 
        
        <!-- <input type="text" placeholder="Meals per day" class="form-control textField textContent"/> -->
    </div>
   </div>

</div>

<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Generate Meal For</div>
</div>

<div class="row mt-4">
    <div class="col-2 ">
        <div class="activeCol">
            <div class="titleName">Breakfast</div>
            <div class="crossMain">
            <div class="cross">X</div>
            </div>
        </div> 
    </div>

    <div class="col-2 ">
       <div class="colDiv">
            <div class="titleName">Lunch</div>
                <div class="crossMain">
                <div class="cross">+</div>
            </div>
        </div>
    </div>

    <div class="col-2 ">
        <div class="colDiv">
             <div class="titleName">Dinner</div>
                 <div class="crossMain">
                 <div class="cross">+</div>
             </div>
         </div>
     </div>

     <div class="col-2 ">
        <div class="colDiv">
             <div class="titleName">Snack</div>
                 <div class="crossMain">
                 <div class="cross">+</div>
             </div>
         </div>
     </div>
</div>

<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Meal Breakdown</div>
</div>

<div class="row mt-4">
    <div class="col">
        <div class="mealTitle">For Breakfast</div>
        <div class="flex rightBorder mt-3">
            <input type="text" class="form-control textField textContent" placeholder="35%"/> &nbsp;&nbsp; <input type="text" placeholder="950 kcal" class="form-control textField textContent"/>
        </div>
    </div>

    <div class="col">
        <div class="mealTitle">For Lunch</div>
        <div class="flex rightBorder mt-3">
            <input type="text" class="form-control textField textContent" placeholder="65%"/> &nbsp;&nbsp; <input type="text" placeholder="350 kcal" class="form-control textField textContent"/>
        </div>
    </div>

    <div class="col">
        <div class="mealTitle">For Dinner</div>
        <div class="flex rightBorder mt-3">
            <input type="text" class="form-control textField textContent" placeholder="43%"/> &nbsp;&nbsp; <input type="text" placeholder="560 kcal" class="form-control textField textContent"/>
        </div>
    </div>

    <div class="col">
        <div class="mealTitle">For Snack</div>
        <div class="flex rightBorder mt-3">
            <input type="text" class="form-control textField textContent" placeholder="30%"/> &nbsp;&nbsp; <input type="text" placeholder="450 kcal" class="form-control textField textContent"/>
        </div>
    </div>
</div>


<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Cuisine Preference</div>
</div>

<div class="row mt-4">
    <div class="col-4">
        <div class="mealTitle">Default Cuisine</div>
        <div class="flex mt-3">
            <div class="col-4 activeCol1">
                <div class="curisieSubTitle">American</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>

            <div class="col-4 activeCol1 ml-2">
                <div class="curisieSubTitle">Indian</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>

            <div class="col-4">
                <div class="cross1Main">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div>
            <!-- <div class="col">
                <div class="cross1Main">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div> -->
        </div>

       
    </div>

    <div class="col-4">
        <div class="mealTitle">Default Cuisine</div>
        <div class="flex mt-3">
            <div class="col-4 colDiv1">
                <div class="curisieSubTitle">American</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>

            <div class="col-4 colDiv1 ml-2">
                <div class="curisieSubTitle">Indian</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>
            <div class="col-3">
                <div class="cross1Main">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div>
            <!-- <div class="col">
                <div class="cross1Main">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div> -->
        </div>

       
    </div>
    <div class="col-4">
        <div class="mealTitle">Default Cuisine</div>
        <div class="flex mt-3">
            <div class="col-4 colDivRed">
                <div class="curisieSubTitleRed">American</div>
                <div class="crossMain">
                <div class="crossRed">X</div>
                </div>
            </div>

            <div class="col-4 colDivRed ml-2">
                <div class="curisieSubTitleRed">Indian</div>
                <div class="crossMain">
                <div class="crossRed">X</div>
                </div>
            </div>
            <div class="col-4">
                <div class="cross1Main">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div>
            <!-- <div class="col">
                <div class="cross1Main">
                    <div class="cross2Sub">
                    <div class="cross1">+</div>
                    </div>
                    </div>
            </div> -->
        </div>

       
    </div>
</div>

<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Lifestyle</div>
</div>

<div class="row mt-5">
    <div class="mealTitle">Cooking Time</div>
</div>

<div class="row  flex">
    <div class="flex mt-3">
        <input type="text" class="form-control textField1 textContent" placeholder="Breakfast Prep Time"/> &nbsp;&nbsp; <input type="text" placeholder="Lunch Prep Time" class="form-control textField1 textContent"/>
        &nbsp;&nbsp; <input type="text" class="form-control textField1 textContent" placeholder="Dinner Prep Time"/> &nbsp;&nbsp; <input type="text" placeholder="Snack Prep Time" class="form-control textField1 textContent"/>
    </div>
</div>


<div class="row mt-5 flex">
    <div class="col-3">
        <div class="mealTitle">Kid Friendly</div>
    
        <div class="flex mt-4">
            <div class="col-4 activeCol1">
                <div class="curisieSubTitle">Yes</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>
            
        </div>
    </div>

    <div class="col-3">
        <div class="mealTitle">Meal Planning Friendly </div>
    
        <div class="flex mt-4">
            <div class="col-4 activeCol1">
                <div class="curisieSubTitle">Yes</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>
            <div class="col-4 ml-2 colDiv1">
                <div class="curisieSubTitle">No</div>
                <div class="crossMain">
                <div class="cross">X</div>
                </div>
            </div>
        </div>
    </div>
    
    
</div>


<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Allergies</div>
</div>

<div class="row mt-2">
    <div class="mealTitle">These are the food that are allergic to the member</div>
</div>

<div class="row mt-3">
    <div class="flex mt-4">
        <div class="col activeCol">
            <div class="curisieSubTitle">Peanuts</div>           
        </div>

        <div class="col ml-1 activeCol">
            <div class="curisieSubTitle">Milk</div>           
        </div>
        <div class="col ml-1 activeCol">
            <div class="curisieSubTitle">Wheat</div>           
        </div>

        <div class="col ml-1 activeCol">
            <div class="curisieSubTitle">Egg</div>           
        </div>

        <div class="col ml-1 activeCol">
            <div class="curisieSubTitle">Soya</div>           
        </div>
    </div>
</div>


<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Ingredient Preference</div>
</div>

<div class="row mt-2">
    <div class="mealTitle">These are the ingredients that are preferred by the member</div>
</div>

<div class="row mt-3">
    <div class="col-2 ml-1 activeCol2">
        <div class="curisieSubTitle">Avocado</div>
        <div class="crossMain">
        <div class="cross">X</div>
        </div>
    </div>

    <div class="col-2 ml-1 activeCol2">
        <div class="curisieSubTitle">Avocado</div>
        <div class="crossMain">
        <div class="cross">X</div>
        </div>
    </div>


    <div class="col-2 ml-1 activeCol2">
        <div class="curisieSubTitle">Avocado</div>
        <div class="crossMain">
        <div class="cross">X</div>
        </div>
    </div>

    <div class="col-2 ml-1 activeCol2">
        <div class="curisieSubTitle">Avocado</div>
        <div class="crossMain">
        <div class="cross">X</div>
        </div>
    </div>

    <div class="col-2">
        <div class="cross1Main">
            <div class="cross2Sub">
            <div class="cross1">+</div>
            </div>
            </div>
    </div>
</div>

<div class="col-md-12 mt-5 textAlign">
    <button class="btn btnClass" >Generate Meal Plan</button>
</div>