import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-enagram-test',
  standalone: true,
  imports: [MatListModule,NgForOf],
  templateUrl: './enagram-test.component.html',
  styleUrl: './enagram-test.component.css'
})
export class EnagramTestComponent {
  questions:any=[];

  ngOnInit() {
    this.questions=[
      {'question':'At a buffet, I','options':['Would prefer something made especially for me','Employ a disciplined approach to food selection','Think, Wow! So much choice!']},
      {'question':'My philosophy regarding food is','options':['Eat or be eaten','It’s a reflection of my success to be seen eating trendy foods','Cooking is love made visible']},
      {'question':'My ideal restaurant is..,','options':['Somewhere upmarket, new, and trendy','Romantic and unusual','Somewhere I’ve been before that’s a safe bet for a good meal']},
      {'question':'when it comes to leftovers, I...','options':['Cater carefully so that there seldom is wastage','Am thrilled—it’ll save cooking again for days','Give them to the homeles']},
      {'question':'When I am given an unusual dish, I..','options':['am suspicious about its contents','am excited to try something new','can’t wait to photograph and blog about it on Instagram']},
      {'question':'When shopping, I look for...','options':['Things I can buy in bulk to avoid extra shopping trips.','Whatever looks great, I just buy','Artisanal items that excite my love of good food']},
      {'question':'When the food at the restaurant is disappointing, I..','options':['complain – it’s not acceptable','say nothing – I don’t make a fuss','go into the kitchen to tell the chef']},
      {'question':'I enjoy eating the same meal every lunchtime...','options':['never – variety is the spice of life','absolutely – then I don’t have to think about it','Yes, it’s less work']},
      {'question':'When it comes to dishing up food, I','options':['like to dish up for my partner','choose what I want to eat','enjoy my partner feeding me morsels']},
      {'question':'When it comes to ordering at a restaurant, I..','options':['Choose what most people are having','Choose what will make me look good and sophisticated','Choose an expensive dish if I’m not paying']}
    ];
  }
}
