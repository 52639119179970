<div class="col-md-12 flex mt-5">
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat1.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Breakfast</div>
                <hr style="background:#1EA247;border:2px solid"> 
            </div>
            <div class="col-md-12 mt-3">
                <div class="desc">Overall calories taken:{{eat.breakfast.average}}Kcal</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Breakfast Time: 9:00 AM (IST)</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat.breakfast.goal}} Kcal</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat2.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Lunch</div>
                <hr style="background:#D02985;border:2px solid">
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall calories taken: {{eat.lunch.average}} Kcal</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Lunch Time: 01:00 PM (IST)</div>
            </div>
            
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat.lunch.goal}} Kcal</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat3.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Dinner</div>
                <hr style="background:#CEAD00;border:2px solid"> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall calories taken: {{eat.dinner.average}} Kcal</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Dinner Time: 07:00 PM (IST)</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat.dinner.goal}} Kcal</div>
            </div>
        </div>
    </div>
    </div>