<div class="col-md-9 float">

<mat-checkbox >Post meal</mat-checkbox> &nbsp;<mat-checkbox >Fasting</mat-checkbox>
<div class="col mt-3">
    <img src="/assets/img/map_img.png" />
</div>
<app-gulcose-value></app-gulcose-value>


</div>

<!-- <div class="col-md-4 float ">
    <div class="noteSection">Notes Section</div>
    <app-notes></app-notes>
</div> -->