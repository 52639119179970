import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { CommonImageComponent } from '../login/common-image/common-image.component';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-reset-link',
  standalone: true,
  imports: [MatListModule,CommonImageComponent,RouterLink],
  templateUrl: './reset-link.component.html',
  styleUrl: './reset-link.component.css'
})
export class ResetLinkComponent {

}
