import { Component } from '@angular/core';
import { NutritationComponent } from './nutritation/nutritation.component';
import { EatingComponent } from './eating/eating.component';
import { MemberDashboardService } from '../member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-life-style',
  standalone: true,
  imports: [NutritationComponent,EatingComponent],
  templateUrl: './life-style.component.html',
  styleUrl: './life-style.component.css'
})
export class LifeStyleComponent {
  lifeStyle:any=[];
  memberId:any=this.route.snapshot.queryParamMap.get('memberId');
  constructor(private dashboard:MemberDashboardService,private route:ActivatedRoute) {
  }
  ngOnInit(): void {
 
    this.getLifeStyle('2024-08-22','2024-09-17');
   
  }

  getLifeStyle(startDate:any,endDate:any){
    this.dashboard.getLifeStyle(this.memberId,startDate,endDate).subscribe(response => {    
        this.lifeStyle = response;
        this.lifeStyle = this.lifeStyle.result;
        console.log(this.lifeStyle);
    });
  }
}
