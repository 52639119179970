<div class="col-md-12 heading mt-2">Activity</div>

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">1. What is your activity level?</div>
    <div class="medicen mt-4">
        <div class="name">Very Active</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">2. How much physical activity do you do weekly/daily?</div>
        <div class="medicen mt-4">
            <div class="name">4 to 6 hours</div>
            <div class="cross">
               
            </div>
        </div>
    
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

      



    

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">3. What kind of activity do you like?</div>
    <div class="medicen mt-4">
        <div class="name">Group Based Activity</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">4. Would you like to have a fitness trainer</div>
        <div class="medicen mt-4">
            <div class="name">No</div>
            <div class="cross">
               
            </div>
        </div>
    
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

        
        <div class="col-md-6 mainDiv mt-4 float">
            <div class="col-md-12 question">5. Would you like to do any of these physical activities?</div>
            <div class="col-md-5 mt-4 float">
                <div class="medicen mt-4">
                    <div class="name">Yoga</div>
                    <div class="cross">
                       
                    </div>
                </div>
            </div>
            <div class="col-md-5 mt-4 float">
                <div class="medicen mt-4">
                    <div class="name">Gym</div>
                    <div class="cross">
                       
                    </div>
                </div>
            </div>


            <div class="col-md-5 mt-4 float">
                <div class="medicen mt-4">
                    <div class="name">Cycling</div>
                    <div class="cross">
                       
                    </div>
                </div>
            </div>
            <div class="col-md-5 mt-4 float">
                <div class="medicen mt-4">
                    <div class="name">Running/Walking</div>
                    <div class="cross">
                       
                    </div>
                </div>
            </div>
        
        
        
            <div class="col-md-12 mt-5">
        
                <div class="btnClass">
                    <button class="btn btnDiv">Edit</button>
                </div>
        
            </div>
            </div>



          