<div class="row flex fontfamily" >
   <div class="col-md-6 ">
       <app-common-image></app-common-image>
   </div>
   <div class="col-md-5 ml-3 marginTop">
       <div class="col-md-12 welcomeMsg">
          Forgot Password
       </div>

       <div class="col-md-12 mt-2 subTitle">
           Enter your email address and get link over email id
       </div>

       <div class="col-md-12 mt-4">
         <div class="col-md-12 welcomeMsg">
            Reset link sent
         </div>
        
         <div class="col-md-12 mt-2 subTitle">
            We have sent you an password reset link to you email address
        kindly check it.
         </div>
        
         <div class="col-md-12 mt-3 stepTitle">
            Steps to reset password
         </div>
        
   
        
         <div class="col-md-12 mt-3">
            <mat-list role="list" class="list">
                <mat-list-item role="listitem">Open your email inbox</mat-list-item>
                <mat-list-item role="listitem">Look for an email from HealD with the subject line “Password Reset Link.</mat-list-item>
                <mat-list-item role="listitem">Click on the provided password reset link.</mat-list-item>
                <mat-list-item role="listitem">Once the email is verified the link will redirect you to the password reset screen.</mat-list-item>
                <mat-list-item role="listitem">Once the email is verified the link will redirect you to the password reset screen.</mat-list-item>
                <mat-list-item role="listitem">Reset you account with your new password.</mat-list-item>
                <mat-list-item role="listitem">Note: The link is valid for 5 mins</mat-list-item>
              </mat-list>
              <a  [routerLink]="['/']"  class=" remember" for="exampleCheck1" style="float:right">Login</a>
         </div>
        
        
       </div>
      
   </div>
</div>