<div id="content-page" class="content-page">
    <div class="container-fluid">
        
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="iq-card-body">
                <div class="title">Team List Overview</div>                  
              </div>            
          </div>
       </div>
    
  </div>
  <div class="row mt-4 flex">
    <div class="col">
      <div class="iq-search-bar">
        <form action="#" class="searchbox">
           <input type="text" class="text search-input searchInner" placeholder="Search By Team Name">
           <a class="search-link" href="#" >
            <i class="fa-solid fa-magnifying-glass" style="color:#000"></i></a>
        </form>
     </div>
    </div>
  
    <div class="col">
      <div class="iq-search-bar">
        <form action="#" class="searchbox">
           <input type="text" class="text search-input searchInner" placeholder="Search By Date">
           <a class="search-link" href="#" >
            <i class="fa-solid fa-magnifying-glass" style="color:#000"></i></a>
        </form>
     </div>
    </div>
  
    <div class="col" style="margin-top:-5px;">
      <button mat-flat-button color="primary" class="color">Apply</button>
    </div>
    </div>

    <div class="row flex mt-4 align-items-start">        
        <app-team-card class="col-4" *ngFor='let team of teamList' [teamdata]="team" [screentype] = "1"></app-team-card>
        <!-- <app-member-card class="col" *ngFor='let member of memberList;' [memberdata]="member" [screentype] = "1"></app-member-card> -->
    </div>

    <mat-paginator [length]="this.totalRecords"
      [pageSize]="10"
      (page)="getNextPage($event)"
      aria-label="Select page">
    </mat-paginator>
    
    </div>
    </div>