import { Component } from '@angular/core';

@Component({
  selector: 'app-medication',
  standalone: true,
  imports: [],
  templateUrl: './medication.component.html',
  styleUrl: './medication.component.css'
})
export class MedicationComponent {

}
