import { Component } from '@angular/core';

@Component({
  selector: 'app-gulcose-value',
  standalone: true,
  imports: [],
  templateUrl: './gulcose-value.component.html',
  styleUrl: './gulcose-value.component.css'
})
export class GulcoseValueComponent {

}
