import { Component } from '@angular/core';

@Component({
  selector: 'app-meal-notification',
  standalone: true,
  imports: [],
  templateUrl: './meal-notification.component.html',
  styleUrl: './meal-notification.component.css'
})
export class MealNotificationComponent {

}
