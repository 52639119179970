import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NotesComponent } from '../notes/notes.component';
import { GulcoseValueComponent } from './gulcose-value/gulcose-value.component';
import { MemberDashboardService } from '../member-dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gulcose',
  standalone: true,
  imports: [MatCheckboxModule,NotesComponent,GulcoseValueComponent],
  templateUrl: './gulcose.component.html',
  styleUrl: './gulcose.component.css'
})
export class GulcoseComponent {

  gulcoseDetails:any=[];
  memberId:any=this.route.snapshot.queryParamMap.get('memberId');
  constructor(private dashboard:MemberDashboardService,private route:ActivatedRoute) {
  }
  ngOnInit(): void {
 
    this.getGulcose('2024-08-22','2024-09-17');
   
  }

  getGulcose(startDate:any,endDate:any){
    this.dashboard.getGulcose(this.memberId,startDate,endDate).subscribe(response => {    
        this.gulcoseDetails = response;
        this.gulcoseDetails = this.gulcoseDetails.result;
        console.log(this.gulcoseDetails);
    });
  }
}
