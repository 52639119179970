import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { MealNotificationComponent } from './meal-notification/meal-notification.component';
import { MemberMealDetailsComponent } from './member-meal-details/member-meal-details.component';

@Component({
  selector: 'app-meal-plan',
  standalone: true,
  imports: [MatListModule,MealNotificationComponent,MemberMealDetailsComponent],
  templateUrl: './meal-plan.component.html',
  styleUrl: './meal-plan.component.css'
})
export class MealPlanComponent {

}
