import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';
import { Observable } from 'rxjs';
import { Member } from '../models/member';

@Injectable({
  providedIn: 'root'
})
export class MemberDashboardService {

  private apiBaseUrl:string;

  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }

   getMemberById(id:any){
    const headers = this.sharedService.createAuthorizationHeader();
    
    const url = this.apiBaseUrl + ControllerApiPath.members+'/GetMemberById?memberId='+id;
    return this.http.get<Member>(url,{ headers }).pipe(
      map((member: Member) => {
        return member;
      }));
   }

   getMemberScore(id:any,data:any){
    const headers = this.sharedService.createAuthorizationHeader();
    
    const url = this.apiBaseUrl + ControllerApiPath.scores+'?memberId='+id+'&date='+data;
    return this.http.get<Member>(url,{ headers }).pipe(
      map((member: Member) => {
        return member;
      }));
   }

   getLabWork(id:any){
    const headers = this.sharedService.createAuthorizationHeader();    
    const url = this.apiBaseUrl + ControllerApiPath.labs+'?memberId='+id;
    return this.http.get<Member>(url,{ headers });
   }

   getVital(id:any,date:any){
    const headers = this.sharedService.createAuthorizationHeader();    
    const url = this.apiBaseUrl + ControllerApiPath.vital+'?memberId='+id+'&date='+date;
    return this.http.get<Member>(url,{ headers });
   }

   getGulcose(memberId:any,fromDt:any,toDt:any){
    const headers = this.sharedService.createAuthorizationHeader();    
    const url = this.apiBaseUrl + ControllerApiPath.healthData+'/glucose?memberId='+memberId+'&startDate='+fromDt+'&endDate='+toDt;
    return this.http.get<Member>(url,{ headers });
   }

   getLifeStyle(memberId:any,fromDt:any,toDt:any){
    const headers = this.sharedService.createAuthorizationHeader();    
    const url = this.apiBaseUrl + ControllerApiPath.healthData+'/nutrition?memberId='+memberId+'&startDate='+fromDt+'&endDate='+toDt;
    return this.http.get<Member>(url,{ headers });
    
   }
}
