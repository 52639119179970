
<mat-card class="memberPartern ">
    
    <mat-card-header>
      <app-member-basic-detail [memberbasic]="memberdata" [screentype] = "3" ></app-member-basic-detail>
      <!-- <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
      <mat-card-title class="ml-3">{{memberdata.member}}</mat-card-title>
      <mat-card-subtitle class="ml-3">Age: {{memberdata.age}}</mat-card-subtitle> -->
    </mat-card-header>
     
    <mat-card-content>
       <app-member-details [memberbasic]="memberdata" [screentype] = "3" ></app-member-details>
    </mat-card-content>
    <mat-card-actions class="mt-4  ">
        <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/video.png" /></a>
      
        <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/msg.png" /></a>
        <a [routerLink]="['/member-details']" [queryParams]="{ id:memberdata.id,islist:1}" class="memberDashboard1" mat-button>View Details</a>
        <a [routerLink]="['/member-dashboard']" [queryParams]="{ memberId:memberdata.id}" class="memberDashboard" mat-button>Member Dashboard</a>
    </mat-card-actions>
  </mat-card>
