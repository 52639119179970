<mat-list role="list" >
    <mat-list-item role="listitem">               
            <div class="optionMain">

                <div class="col optionText">Never</div>
                <div class="radio"></div>
            </div>
    </mat-list-item>

    

    <mat-list-item role="listitem" class="mt-3">               
        <div class="optionMain">

            <div class="col optionText">Rarely</div>
            <div class="radio"></div>
        </div>
    </mat-list-item>

    <mat-list-item role="listitem" class="mt-3">               
        <div class="optionMain">

            <div class="col optionText">Sometimes</div>
            <div class="radio"></div>
        </div>
    </mat-list-item>

    <mat-list-item role="listitem" class="mt-3">               
        <div class="optionMain">

            <div class="col optionText">Very Often</div>
            <div class="radio"></div>
        </div>
    </mat-list-item>


</mat-list>