import { Component,Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-member-basic-detail',
  standalone: true,
  imports: [MatCardModule,NgIf],
  templateUrl: './member-basic-detail.component.html',
  styleUrl: './member-basic-detail.component.css'
})
export class MemberBasicDetailComponent {
  @Input() memberbasic:any;
  @Input() screentype:any;

 
}
