<div class="col-md-12">
    <div class="col-md-6 float mt-5">
        <div class="question ">1. How much physical activity do you do daily/weekly?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>
    <div class="col-md-6 float mt-5">
        <div class="question ">2. Do you enjoy individual or group fitness activities?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">3. Who cooks the food in your household?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>
    <div class="col-md-6 float mt-5">
        <div class="question ">4. How much does the household spend on groceries?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">5. What is your favourite restaurant?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>
    <div class="col-md-6 float mt-5">
        <div class="question ">6. How often do you eat there?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">7. How much you spend on eating outside?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>
    <div class="col-md-6 float mt-5">
        <div class="question ">8. Is food prepared in family style or for individual taste?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">9. Are yiu willing to try alternative cuisines, grains, vegetables?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>
    <div class="col-md-6 float mt-5">
        <div class="question ">10. How much pre-packaged food do you consume?</div>
       <app-question-options></app-question-options>
    </div>

    <div class="col-md-12 float mt-5">
        <button type="submit" class="btn btnClass">Save</button>
     </div>
</div>