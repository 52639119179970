import { Component } from '@angular/core';

@Component({
  selector: 'app-dietary-details',
  standalone: true,
  imports: [],
  templateUrl: './dietary-details.component.html',
  styleUrl: './dietary-details.component.css'
})
export class DietaryDetailsComponent {

}
