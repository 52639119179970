<div class="col-md-12 flex">
    <div class="col-md-9 float">
        <div class="col-md-6 mainSleep float">
            
                <div class="col-md-12 flex">
                    <div class="col-md-3">
                        <img class="img" src="/assets/img/upimage.png"/>
                    </div>  
                    <div class="col-md-8 mt-2">
                       <div class="sleepTitle1"> +5 Hrs </div>

                       <div class="sleepTitle2">Compared to yesterday</div>
                    </div>    
                    
                </div>
                <div class="col-md-12 mt-4">
                    <img class="img" src="/assets/img/sleepimg.png"/>
                </div>
                <div class="col-md-12 mt-2 flex">

                    <div class="col-md-6 sleepIcon">
                        <img  src="/assets/img/sleepiconsmall.png"/> &nbsp; 11:33 PM                       
                    </div>

                    <div class="col-md-6 sleepIcon floatRight">
                        <img  src="/assets/img/wakeupiconsmall.png"/> &nbsp; 08.38 AM                     
                    </div>
                    
                </div>
                <div class="col-md-12 mt-4">
                    <p class="sleepTitle">Refreshed and Rested</p>
                    <p class="sleepContent mt-4">Congratulations achieving excellent sleep score! If you’re feeling energised, you could consider a light exercise</p>
                    <div class="col-md-6 float mt-4">
                        <p class="totalSleepDuration">Sleep Duration</p>
                        <p class="mt-2 sleepTime">8hrs 32 min</p>
                    </div>
                    <div class="col-md-6 float mt-4">
                        <p class="totalSleepDuration">Sleep Goal</p>
                        <p class="mt-2 sleepTime">9hrs 32 min</p>
                    </div>
                </div>

        </div>
        <div class="col-md-6 float ">
            <div class="col-md-5 float sleepSub ">
                <p><img class="img2" src="/assets/img/sleep1.png"/></p>
                <p class="sleepTimeTitle">Deep Sleep</p>
                <p><span class="sleepTime1">4</span><span class="sleepAmPm">hrs</span> &nbsp;<span class="sleepTime1">14</span><span class="sleepAmPm">mins</span></p>
                <p > <button class="btn btnClass">Excellent</button></p>
            </div>

            <div class="col-md-5 float sleepSub ml-1">
                <p><img class="img2" src="/assets/img/sleep2.png"/></p>
                <p class="sleepTimeTitle">Deep Sleep</p>
                <p><span class="sleepTime1">4</span><span class="sleepAmPm">hrs</span> &nbsp;<span class="sleepTime1">14</span><span class="sleepAmPm">mins</span></p>
                <p > <button class="btn btnClass">Excellent</button></p>
            </div>
            <div class="col-md-5 float sleepSub mt-4 ml-1">
                <p><img class="img2" src="/assets/img/sleep3.png"/></p>
                <p class="sleepTimeTitle">Deep Sleep</p>
                <p><span class="sleepTime1">4</span><span class="sleepAmPm">hrs</span> &nbsp;<span class="sleepTime1">14</span><span class="sleepAmPm">mins</span></p>
                <p > <button class="btn btnClass">Excellent</button></p>
            </div>
            <div class="col-md-5 float sleepSub mt-4 ml-1">
                <p><img class="img2" src="/assets/img/sleep4.png"/></p>
                <p class="sleepTimeTitle">Deep Sleep</p>
                <p><span class="sleepTime1">4</span><span class="sleepAmPm">hrs</span> &nbsp;<span class="sleepTime1">14</span><span class="sleepAmPm">mins</span></p>
                <p > <button class="btn btnClass">Excellent</button></p>
            </div>

            
        </div>
    </div>
    <!-- <div class="col-md-4 float" style="margin-top:-70px">
        <div class="noteSection">Notes Section</div>
        <app-notes></app-notes>
    </div>
     -->
    </div>