<!-- <div>
    <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
    <mat-card-title class="ml-3">{{memberbasic.member}}</mat-card-title>
    <mat-card-subtitle class="ml-3">Age: {{memberbasic.age}}</mat-card-subtitle>
  </div> -->
  <div class="row" >
    
      <div class="col-lg-2"><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
      <div class="col-lg-8 name ml-2 mt-1" >
        <div class="col-md-12 name">
            {{memberbasic.personName}}
        </div>
        <div class="col-md-12 age" *ngIf="screentype == 1">
          Age : {{memberbasic.age}}
      </div>
      <div class="col-md-12 age" *ngIf="screentype == 2">
        {{memberbasic.subTitle}}
    </div>
    <div class="col-md-12 age" *ngIf="screentype == 3">
      {{memberbasic.subTitle}} &nbsp;&nbsp; Age : {{memberbasic.age}}
  </div>

  
      </div>
  </div>
