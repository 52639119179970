<div class="col-md-12 heading mt-2">Snacks</div>

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">1. What is your go to snack?</div>
    <div class="medicen mt-4">
        <div class="name">Friuts</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">2. Do you have any time preferences of eating snack?</div>
        <div class="medicen mt-4">
            <div class="name">Sometimes</div>
            <div class="cross">
               
            </div>
        </div>
    
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

        
        <div class="col-md-6 mainDiv mt-4 float">
            <div class="col-md-12 question">3. How often do you binge eat in a week</div>
            <div class="medicen mt-4">
                <div class="name">Eat in small portion</div>
                <div class="cross">
                   
                </div>
            </div>
        
        
            <div class="col-md-12 mt-5">
        
                <div class="btnClass">
                    <button class="btn btnDiv">Edit</button>
                </div>
        
            </div>
            </div>



    <div class="col-md-12 heading mt-5">Dining Out</div>

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">1. What is your favourite restaurant style food?</div>
    <div class="medicen mt-4">
        <div class="name">Mexican</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">2. How often do you eat there?</div>
        <div class="medicen mt-4">
            <div class="name">Rarely</div>
            <div class="cross">
               
            </div>
        </div>
    
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

        
        <div class="col-md-6 mainDiv mt-4 float">
            <div class="col-md-12 question">3. While celebrating special occasions, where do you eat?</div>
            <div class="medicen mt-4">
                <div class="name">Outside</div>
                <div class="cross">
                   
                </div>
            </div>
        
        
            <div class="col-md-12 mt-5">
        
                <div class="btnClass">
                    <button class="btn btnDiv">Edit</button>
                </div>
        
            </div>
            </div>



            <div class="col-md-12 heading mt-5">Fasting & Eating Habits</div>

            <div class="col-md-6 mainDiv mt-2 float">
                <div class="col-md-12 question">1. What is your favourite restaurant style food?</div>
                <div class="medicen mt-4">
                    <div class="name">Mexican</div>
                    <div class="cross">
                       
                    </div>
                </div>
            
                <div class="col-md-12 mt-5">
            
                    <div class="btnClass">
                        <button class="btn btnDiv">Edit</button>
                    </div>
            
                </div>
                </div>
            
                <div class="col-md-6 mainDiv mt-2 float">
                    <div class="col-md-12 question">2. Have you ever tried millet based recipes? like Bhajra, Chappati, Quinoa etc</div>
                    <div class="medicen mt-4">
                        <div class="name">Yes</div>
                        <div class="cross">
                           
                        </div>
                    </div>
                
                
                    <div class="col-md-12 mt-5">
                
                        <div class="btnClass">
                            <button class="btn btnDiv">Edit</button>
                        </div>
                
                    </div>
                    </div>
            
                    
                    <div class="col-md-6 mainDiv mt-4 float">
                        <div class="col-md-12 question">3. Do you recognise when you’re full or do you tend to overeat?</div>
                        <div class="medicen mt-4">
                            <div class="name">No, I stick to my meal timings</div>
                            <div class="cross">
                               
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                    </div>


                    <div class="col-md-6 mainDiv mt-4 float">
                        <div class="col-md-12 question">4. Do you feel hungry soon after finishing a meal?</div>
                        <div class="medicen mt-4">
                            <div class="name">Yes, I tend to feel hungry</div>
                            <div class="cross">
                               
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                    </div>


                    <div class="col-md-6 mainDiv mt-4 float">
                        <div class="col-md-12 question">5. Do you eat when you’re feeling emotional, unhappy or bored?</div>
                        <div class="medicen mt-4">
                            <div class="name">No, I don’t eat anything</div>
                            <div class="cross">
                               
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                    </div>


                    <div class="col-md-12 heading mt-5">Allergies</div>

                    <div class="col-md-6 mainDiv mt-2 float">
                        <div class="col-md-12 question">1. Do you have any known food allergies?</div>
                        <div class="col-md-5 float">
                            <div class="medicen mt-4">
                                <div class="name">Nuts</div>
                                <div class="cross">
                                   
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 ml-1 float">
                            <div class="medicen mt-4">
                                <div class="name">Wheat</div>
                                <div class="cross">
                                   
                                </div>
                            </div>
                        </div>
                        
                    
                        <div class="col-md-12 mt-5 float">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                        </div>