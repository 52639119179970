<div class="col-md-12 flex mt-5">
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/glus1.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Average Fasting Glucose</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">148 mg/dl</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>

    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img style="width: 49%;" src="/assets/img/memberdashboard/glus2.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Average Post Meal Glucose</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">162 mg/dl</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>

    <div class="col ">
        <div class="gridSub ">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/glus5.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Glucose Variability</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">177 mg/dl</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 flex mt-5">
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/glus1.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Average Time in range</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">70%</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>

    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img style="width: 49%;" src="/assets/img/memberdashboard/glus2.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Highest Glucose Level</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">202 mg/dl</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>

    <div class="col ">
        <div class="gridSub ">
            <div class="col-md-12 flex">
                <div class="col-md-6 float"><img src="/assets/img/memberdashboard/glus5.png"/></div>
                <div class="col-md-6 float ml-2" style="text-align: right;">4:16 PM</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Lowest Glucose Level</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">74 mg/dl</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>
</div>