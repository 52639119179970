import { Component, Input,inject, model, signal  } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MemberBasicDetailComponent } from './member-basic-detail/member-basic-detail.component';
import { BloodPressureComponent } from './blood-pressure/blood-pressure.component';
import { RouterLink } from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

@Component({
  selector: 'app-member-card',
  standalone: true,
  imports: [MatCardModule,MemberBasicDetailComponent,BloodPressureComponent,RouterLink],
  templateUrl: './member-card.component.html',
  styleUrl: './member-card.component.css'
})
export class MemberCardComponent {
  @Input() memberdata:any;

  readonly animal = signal('');
  readonly name = model('');
  readonly dialog = inject(MatDialog);
  public dialogRef: MatDialogRef<DialogboxComponent>
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogboxComponent, {
      data: {name: this.name(), animal: this.animal()},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result !== undefined) {
        this.animal.set(result);
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}



@Component({
  selector: 'dialogbox',
  templateUrl: './dialogbox/dialogbox.component.html',
  styleUrl: './dialogbox/dialogbox.component.css',
  standalone: true,
  imports: [ MatDialogModule],
  
})

export class DialogboxComponent {
}