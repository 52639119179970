import { Component } from '@angular/core';
import { CommonImageComponent } from '../login/common-image/common-image.component';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-forget-password',
  standalone: true,
  imports: [CommonImageComponent,RouterLink,FormsModule],
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.css'
})
export class ForgetPasswordComponent {
  email:any='';
  constructor(private router:Router,private LoginService:LoginService) {
  }
  forgetPassword(){
    if (this.email == '') {
      //this.toastr.error("Password is required");
      return;
    }else{
    
    this.LoginService.checkEmailId(this.email).subscribe(response => {
      this.router.navigate(['/dashboard']);
     
    });
  }
  }
}
