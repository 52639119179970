import { Component } from '@angular/core';
import { Input } from '@angular/core';
@Component({
  selector: 'app-nutritation',
  standalone: true,
  imports: [],
  templateUrl: './nutritation.component.html',
  styleUrl: './nutritation.component.css'
})
export class NutritationComponent {
    @Input() nutri:any;
}
