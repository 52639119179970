
<div class="col-md-12  mt-3">
<div class="col-md-6 foodLogs  float mt-2">
    <div class="col-md-2 float">
        <img class="imgClass" src="{{foodAllInfo.foodImageUrl}}"/>
    </div>
    <div class="col-md-10 float">
        <div class="col-md-12 foodTitle">
            {{foodAllInfo.name}}
        </div>
        <div class="col-md-12 mt-3 padding" >

            <mat-list class="foodDetails" style="display:flex;">  
                <mat-list-item class="foodDetails borderRight"> {{foodAllInfo.logTime.split('T')[0]}} </mat-list-item>  
                <mat-list-item class=" foodDetails borderRight"> {{getDate(foodAllInfo.logTime.split('T')[1].split('.')[0])}}  </mat-list-item>  
                <!-- <mat-list-item class="foodDetails borderRight"> {{foodAllInfo.serving}} serving  </mat-list-item>   -->
                <mat-list-item class="foodDetails"> {{foodAllInfo.mealType}}  </mat-list-item>  
            </mat-list>  

        </div>
    </div>

    <div class="col-md-12 mt-3 padding float" >

        <mat-list class="foodDetails" style="display:flex;">  
            <mat-list-item class="foodDetails "> Total Calories </mat-list-item>  
            <mat-list-item class=" foodDetails "> Protien  </mat-list-item>  
            <mat-list-item class="foodDetails "> Carbs  </mat-list-item>  
            <mat-list-item class="foodDetails"> Fats  </mat-list-item>  
           </mat-list>  

    </div>

    <div class="col-md-12 mt-3 padding float" >

        <mat-list class="foodDetails" style="display:flex;">  
            <mat-list-item class="foodDetails "> <b>{{foodAllInfo.total.calories}}</b> Cals </mat-list-item>  
            <mat-list-item class=" foodDetails "> <b>{{foodAllInfo.total.protein}}</b> gram  </mat-list-item>  
            <mat-list-item class="foodDetails "> <b>{{foodAllInfo.total.carbs}}</b> Carbs  </mat-list-item>  
            <mat-list-item class="foodDetails"> <b>{{foodAllInfo.total.fat}}</b> Fats  </mat-list-item>  
           </mat-list>  

    </div>
    <div class="col-md-12 align mt-4" *ngIf="foodAllInfo.serving == null">
        <a [routerLink]="['/edit-food-log']" [queryParams]="{ id:foodAllInfo.id,memberId:member}" type="submit" class="btnClass">Edit</a>
    </div>
</div>




</div>



