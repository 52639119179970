<div class="col-md-12 mt-3">
    <div class="col-md-6 float mt-4">
        <div class="title">1. What is your Profession?</div>       
            <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>        
    </div>
    <div class="col-md-6 float mt-4">
        <div class="title">2. Any workout or yoga?</div>       
            <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-4">
        <div class="title">3. Diabetes happened?</div>       
            <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>        
    </div>
    <div class="col-md-6 float mt-4">
        <div class="title">4. Sleep Quality</div>       
            <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-4">
        <div class="title">5. What time do you go to bed?</div>       
        <input type="time" class="form-control select"/>     
    </div>
    <div class="col-md-6 float mt-4">
        <div class="title">6. What time do you wake up?</div>       
        <input type="time" class="form-control mt-3 select"/> 
    </div>

    <div class="col-md-6 float mt-4">
        <div class="title">7. Do you drink?</div>       
           <select class="form-control mt-3 select"></select>        
    </div>
    <div class="col-md-6 float mt-4">
        <div class="title">8. Do you smoke?</div>       
        <select class="form-control mt-3 select"></select>
    </div>

    <div class="col-md-6 float mt-4">
        <div class="title">9. What are your working hours?</div>       
        <select class="form-control mt-3 select"></select>       
    </div>
    <div class="col-md-6 float mt-4">
        <div class="title">10. How often do you dine out in a month?</div>       
        <select class="form-control mt-3 select"></select>
    </div>
    <div class="col-md-6 float mt-4">
        <div class="title">11. Have you tried any medication?</div>       
        <select class="form-control mt-3 select"></select>
    </div>
    <div class="col-md-12 float mt-5">
        <button type="submit" class="btn btnClass">Save</button>
     </div>
</div>

