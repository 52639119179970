<div class="col-md-12 mt-5" >
    <div class="col-md-6 float mt-3" *ngFor='let ques of questions;let i=index;'>
        <div class="col-md-12">
            <div class="question">{{i+1}} : {{ques.question}}</div>
        </div>
    
        <div class="col-md-12 mt-3">
            <mat-list role="list" >
                <mat-list-item class="mt-3" role="listitem" *ngFor='let option of ques.options;'>               
                        <div class="optionMain">
            
                            <div class="col optionText">{{option}}</div>
                            <div class="radio"></div>
                        </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

   
   
</div>