import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';

@Component({
  selector: 'app-create-plan',
  standalone: true,
  imports: [BasicDetailsComponent],
  templateUrl: './create-plan.component.html',
  styleUrl: './create-plan.component.css'
})
export class CreatePlanComponent {

}
