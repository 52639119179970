import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-basic-details',
  standalone: true,
  imports: [NgIf],
  templateUrl: './basic-details.component.html',
  styleUrl: './basic-details.component.css'
})
export class BasicDetailsComponent {
@Input() screentype:any;
}
