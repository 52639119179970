<div class="row flex">
    <div class="col-md-4 topBox mt-4 flex">
       <div class="col-md-3"><img src="/assets/img/icon.png"/></div> 
        <div class="col-md-7">
            <div class="boxContent mt-3">3 members are going to<br/>complete their meal plan</div>
        </div>
        <div class="col-md-1 crossBtn">
            <img src="assets/img/cross.png"/>
        </div>
    </div>

    <div class="col-md-4 ml-3 topBox mt-4 flex">
        <div class="col-md-3"><img src="/assets/img/icon.png"/></div> 
        <div class="col-md-7">
            <div class="boxContent mt-3">3 members are going to<br/>complete their meal plan</div>
        </div>
        <div class="col-md-1 crossBtn">
            <img src="assets/img/cross.png"/>
        </div>
    </div>
</div>