<div class="col-md-12 mt-4 paddingZero flex" *ngFor="let level of levelCount">
    <div class="col-md-2">
        <img mat-card-sm-image *ngIf="level.memberLevel == 'L1'" class="levelImg" src="/assets/img/L1.png">
        <img mat-card-sm-image *ngIf="level.memberLevel == 'L2'" class="levelImg" src="/assets/img/L2.png">
        <img mat-card-sm-image *ngIf="level.memberLevel == 'L3'" class="levelImg" src="/assets/img/L3.png">
        <img mat-card-sm-image *ngIf="level.memberLevel == 'L4'" class="levelImg" src="/assets/img/L4.png">
        <img mat-card-sm-image *ngIf="level.memberLevel == 'L5'" class="levelImg" src="/assets/img/L5.png">
     </div>
    <div class="col-md-8 ml-4"> 
        
            <div class="memberNumber mt-1">Number of Members</div>
            <div class="memberCount mt-1">{{level.count}}</div>
        
    </div>
</div>
<!-- <div class="col-md-12 mt-5 paddingZero flex">
    <div class="col-md-2"><img mat-card-sm-image class="levelImg" src="/assets/img/L2.png"> </div>
    <div class="col-md-8 ml-4"> 
       
            <div class="memberNumber mt-1">Number of Members</div>
            <div class="memberCount mt-1">{{levelCount[2].count}}</div>
       
    </div>
</div>

<div class="col-md-12 mt-5 paddingZero flex">
    <div class="col-md-2"><img mat-card-sm-image class="levelImg" src="/assets/img/L3.png"> </div>
    <div class="col-md-8 ml-4"> 
       
            <div class="memberNumber mt-1">Number of Members</div>
            <div class="memberCount mt-1">{{levelCount[1].count}}</div>
        
    </div>
</div>


<div class="col-md-12 mt-5 paddingZero flex">
    <div class="col-md-2"><img mat-card-sm-image class="levelImg" src="/assets/img/L4.png"> </div>
    <div class="col-md-8 ml-4"> 
       
            <div class="memberNumber mt-1">Number of Members</div>
            <div class="memberCount mt-1">25</div>
        
    </div>
</div> -->