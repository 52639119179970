<div class="row mt-5">
    <div class="col-md-2">
        <div class="title">Lab Tests</div>
    </div>
    <div class="col-md-5 ml-2">
        <button mat-flat-button color="accent">All Test Ordered</button> &nbsp; <button mat-flat-button disabled>Pending Results</button> &nbsp;<button mat-flat-button disabled>Result Recieved</button>
    </div>
    <div class="col">
      <div class="iq-search-bar">
        <form action="#" class="searchbox">
           <input type="text" class="text search-input searchInner" placeholder="Search By Name">
           <a class="search-link" href="#" >
            <i class="fa-solid fa-magnifying-glass" style="color:#000"></i></a>
        </form>
     </div>
    </div>
    
    <div class="col">
      <button class="btn applyBtn">Apply</button>
    </div>
</div>

<div class="row mt-3 flex">
    <mat-card class="col ">
        <mat-card-header>
          <!-- <app-member-basic-detail  [memberbasic]="memberList" [screentype] = "2"></app-member-basic-detail> -->
            <!-- <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
            <mat-card-title class="ml-3 meetTitle">Rock Lee</mat-card-title>
            <mat-card-subtitle class="ml-3 meetSubTitle">Diabetes Manager</mat-card-subtitle> -->
            <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
            <mat-card-title class="ml-4 meetTitle">Rock Lee</mat-card-title>
            <mat-card-subtitle class="ml-4 meetSubTitle">Diabetes Manager</mat-card-subtitle>
            <div class="col-5 meetSubTitle" style="text-align: right;">
               15 May 2024
            </div>
            
          </mat-card-header>
       
        <mat-card-content>
            <div class="col-md-10 mt-4">
                <div class="followUp">Number Of Test</div>
                <mat-list role="list">
                    <mat-list-item class="listing" role="listitem">HbA1C Test</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Fasting Blood Sugar</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Post prandial Blood Sugar</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Urine ACR</mat-list-item>
                  </mat-list>
            </div>
            <!-- <div class="col-md-12 marginLeft" >
              
               
                <a [routerLink]="['member-dashboard']" class="btn btn-primary" mat-flat-button color="primary">View Dashboard</a>
        </div> -->
          
        </mat-card-content>
      </mat-card>
      <mat-card class="col ml-2">
        <mat-card-header>
            <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
            <mat-card-title class="ml-3 meetTitle">Rock Lee</mat-card-title>
            <mat-card-subtitle class="ml-3 meetSubTitle">Diabetes Manager</mat-card-subtitle>
            <div class="col-5 meetSubTitle" style="text-align: right;">
               15 May 2024
            </div>
            
          </mat-card-header>
       
        <mat-card-content>
            <div class="col-md-10 mt-4">
                <div class="followUp">Number Of Test</div>
                <mat-list role="list">
                    <mat-list-item class="listing" role="listitem">HbA1C Test</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Fasting Blood Sugar</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Post prandial Blood Sugar</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Urine ACR</mat-list-item>
                  </mat-list>
            </div>
            <!-- <div class="col-md-12 marginLeft" >
              
              <a [routerLink]="['member-dashboard']" class="btn btn-primary" mat-flat-button color="primary">View Dashboard</a>
        </div> -->
          
        </mat-card-content>
      </mat-card>

      <mat-card class="col ml-2">
        <mat-card-header>
            <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
            <mat-card-title class="ml-3 meetTitle">Rock Lee</mat-card-title>
            <mat-card-subtitle class="ml-3 meetSubTitle">Diabetes Manager</mat-card-subtitle>
            <div class="col-5 meetSubTitle" style="text-align: right;">
               15 May 2024
            </div>
            
          </mat-card-header>
       
        <mat-card-content>
            <div class="col-md-10 mt-4">
                <div class="followUp">Number Of Test</div>
                <mat-list role="list">
                    <mat-list-item class="listing" role="listitem">HbA1C Test</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Fasting Blood Sugar</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Post prandial Blood Sugar</mat-list-item>
                    <mat-list-item class="listing" role="listitem">Urine ACR</mat-list-item>
                  </mat-list>
            </div>
            <!-- <div class="col-md-12 marginLeft" >
              
              <a [routerLink]="['member-dashboard']" class="btn btn-primary" mat-flat-button color="primary">View Dashboard</a>
            </div>
           -->
        </mat-card-content>
      </mat-card>
</div>
