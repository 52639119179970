import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-member-level',
  standalone: true,
  imports: [NgFor,NgIf],
  templateUrl: './member-level.component.html',
  styleUrl: './member-level.component.css'
})
export class MemberLevelComponent {
    @Input() levelCount:any;
}
