import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { MemberDetailFormComponent } from './member-detail-form/member-detail-form.component';
import { NgIf } from '@angular/common';
import { PersonalityComponent } from './personality/personality.component';
import { LifeStyleComponent } from './life-style/life-style.component';
import { HealthComponent } from './health/health.component';
import { DietaryComponent } from './dietary/dietary.component';
import { FitnessComponent } from './fitness/fitness.component';
import { ActivatedRoute } from '@angular/router';
import { QuestionsService } from './questions.service';
import { AllFoodLogsComponent } from "./all-food-logs/all-food-logs.component";
import { MedicationComponent } from './medication/medication.component';
import { DietaryDetailsComponent } from './dietary-details/dietary-details.component';
import { DietaryPreferenceComponent } from './dietary-preference/dietary-preference.component';
import { LifestyleConditionComponent } from './lifestyle-condition/lifestyle-condition.component';
import { NgClass } from '@angular/common';
import { 
  formatDate 
 } 
  from '@angular/common';

@Component({
  selector: 'app-member-details',
  standalone: true,
  imports: [NgClass,LifestyleConditionComponent,MatListModule, DietaryPreferenceComponent,DietaryDetailsComponent,MedicationComponent,MemberDetailFormComponent, NgIf, PersonalityComponent, LifeStyleComponent, HealthComponent, DietaryComponent, FitnessComponent, AllFoodLogsComponent],
  templateUrl: './member-details.component.html',
  styleUrl: './member-details.component.css'
})
export class MemberDetailsComponent {
  isSubMenu:any=5;
  subMenu:any=7;
  isMemberDairy:boolean=false;
  isLifeStyle:boolean=false;
  isPersonality:boolean=false;
  isHealth:boolean=false;
  isDietary:boolean=false;
  isfitness:boolean=false;
  mainTab:any='Monitor';
  subTab:any='Food';
  constructor(private route: ActivatedRoute,private question:QuestionsService){

  }

  ngOnInit(): void {
    
    // this.question.getQuestion(this.route.snapshot.queryParamMap.get('memberId'),'Personality').subscribe(response => { 
    //   console.log(response);
    // });
    // ;
  }
 

  selectedTab(type:any,subMenu:any,tab:any){
    //  if(type==2){
    //   this.isSubMenu=1;
    //   this.isPersonality=true;
    //  }else{
    //   this.isSubMenu=0;
    //  }
    this.mainTab = tab;
    this.isSubMenu = type;
    this.subMenu=subMenu;
    if(type== 2){
      this.subTab = 'Medication';
    }else  if(type== 5){
      this.subTab = 'Glucose';
    }
     if(type==1){
      this.isMemberDairy = true;
     }else{
      this.isMemberDairy = false;
     }
  }

  subSelectedTab(type:any,tab:any){
   this.diablesubSelected();
   this.subMenu=type;
   this.subTab =tab;
    // if(type == 1){
    //   this.isPersonality=true;
    // }
    // else if(type == 2){
    //   this.isLifeStyle=true;
    // }
    // else if(type == 3){
    //   this.isHealth=true;
    // }
    // else if(type == 4){
    //   this.isDietary=true;
    // }
    // else if(type == 5){
    //   this.isfitness=true;
    // }
  }

  diablesubSelected(){
    // this.isPersonality=false;
    // this.isLifeStyle=false;
    // this.isHealth=false;
    // this.isDietary=false;
    // this.isfitness=false;
    this.subMenu=0;
  }
}
