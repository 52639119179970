<div class="col-md-12 heading mt-2">Addiction</div>

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">1. Do you consume alcohol?</div>
    <div class="medicen mt-4">
        <div class="name">No</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">2. Do you smoke?</div>
        <div class="medicen mt-4">
            <div class="name">No</div>
            <div class="cross">
               
            </div>
        </div>
    
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

      



    <div class="col-md-12 heading mt-5">Work Life</div>

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">1. Help us understand how typical your day is structured?</div>
    <div class="medicen mt-4">
        <div class="name">Work from home</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">2. What is your working hours in a day?</div>
        <div class="medicen mt-4">
            <div class="name">6 to 8 hours</div>
            <div class="cross">
               
            </div>
        </div>
    
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

        
        <div class="col-md-6 mainDiv mt-4 float">
            <div class="col-md-12 question">3. Have your tried meditation?</div>
            <div class="medicen mt-4">
                <div class="name">Yes</div>
                <div class="cross">
                   
                </div>
            </div>
        
        
            <div class="col-md-12 mt-5">
        
                <div class="btnClass">
                    <button class="btn btnDiv">Edit</button>
                </div>
        
            </div>
            </div>



            <div class="col-md-12 heading mt-5">Fasting</div>

            <div class="col-md-6 mainDiv mt-2 float">
                <div class="col-md-12 question">1. Do you carry food everyday when you go to work/office?</div>
                <div class="medicen mt-4">
                    <div class="name">Yes</div>
                    <div class="cross">
                       
                    </div>
                </div>
            
                <div class="col-md-12 mt-5">
            
                    <div class="btnClass">
                        <button class="btn btnDiv">Edit</button>
                    </div>
            
                </div>
                </div>
            


                    <div class="col-md-12 heading mt-5">Sleep</div>

                    <div class="col-md-6 mainDiv mt-2 float">
                        <div class="col-md-12 question">1. How would you rate your sleep quality?</div>
                        <div class="col-md-5 float">
                            <div class="medicen mt-4">
                                <div class="name">Excellent</div>
                                <div class="cross">
                                   
                                </div>
                            </div>
                        </div>

                        
                    
                        <div class="col-md-12 mt-5 float">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                        </div>


                        <div class="col-md-6 mainDiv mt-2 float">
                            <div class="col-md-12 question">2. What is your bed time?</div>
                            <div class="col-md-8 mt-4">
                                
                                <input type="time" class="form-control textField"/>
                            </div>
                        
                            <div class="col-md-12 mt-5">
                        
                                <div class="btnClass">
                                    <button class="btn btnDiv">Edit</button>
                                </div>
                        
                            </div>
                        </div>


                        <div class="col-md-6 mainDiv mt-2 float">
                            <div class="col-md-12 question">3ss. What is your wake up time?</div>
                            <div class="col-md-8 mt-4">
                                
                                <input type="time" class="form-control textField"/>
                            </div>
                        
                            <div class="col-md-12 mt-5">
                        
                                <div class="btnClass">
                                    <button class="btn btnDiv">Edit</button>
                                </div>
                        
                            </div>
                        </div>