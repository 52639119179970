<div class="meal">
    <div class="row flex">
        <div class="col ">
            Breakfast
        </div>
        <div class="col" style="text-align: right;">
            <img style="width:10%" src="/assets/img/cross1.png" />
        </div>
    </div>
    <div class="row flex mt-4">
        <div class="col-2">
            <img src="/assets/img/mealpic.png"/>
        </div>
        <div class="col-9 ml-1">
            <div class="col-md-8 ml-4">
                <div class="mealName">Pork Steaks, 150 g</div>
            </div>
            <div class="row mt-5 flex">
                <div class="col ml-3">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/calories.png"/> &nbsp;
                        <div class="nutriTitle mt-2">Calories</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        410
                    </div>
                    <div class="col-md-12 mt-2 unit ml-4">
                        cal
                    </div>
                </div>
                <div class="col">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/protien.png"/> &nbsp;
                        <div class="nutriTitle mt-2">Protien</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        410
                    </div>
                    <div class="col-md-12 mt-2 unit ml-4">
                        grams
                    </div>
                </div>

                <div class="col">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/carbs.png"/> &nbsp;
                        <div class="nutriTitle mt-2">Carbs</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        410
                    </div>
                    <div class="col-md-12 mt-2 unit ml-4">
                        grams
                    </div>
                </div>
                <div class="col">
                    <div class="col-md-12 flex">
                        <img class="fat" src="/assets/img/fat1.png"/> &nbsp;
                        <div class="nutriTitle mt-2">Fat</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-3">
                        410
                    </div>
                    <div class="col-md-12 mt-2 unit ml-3">
                        grams
                    </div>
                </div>
                
                </div>

                
        </div>
    </div>
    <div class="col-md-12 textAlign mt-4">
        <img src="/assets/img/timmer.png"/> &nbsp;&nbsp; <button class="btn btnClass">View More Details</button>
        </div>
   
</div>