import { Component } from '@angular/core';
import { CommonImageComponent } from '../common-image/common-image.component';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginModel } from '../../models/login';
import { LoginService } from '../login.service';
import { Router } from "@angular/router";
import {  GoogleLoginProvider,GoogleSigninButtonModule,  SocialLoginModule } from '@abacritt/angularx-social-login';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { MatButtonModule } from '@angular/material/button';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonImageComponent,RouterLink,NgIf,FormsModule,SocialLoginModule,GoogleSigninButtonModule,MatButtonModule],
 
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  password1:any;
  show = false;
  userDetails:any={
    email:''
  };
  user: SocialUser;
  loggedIn: boolean;
  loginModel :LoginModel= {username:"",password:""};
  constructor(private LoginService:LoginService,private router:Router,private authService: SocialAuthService) {
   
    this.password1 = 'password';
  }
  ngOnInit() {
    // this.authService.authState.subscribe((user:any) => {
    //   this.user = user;
    //   this.loggedIn = (user != null);
    //   this.userDetails.email = this.user.email;
     
    //   this.LoginService.checkEmailId(this.user.email).subscribe(response => {
    //    // console.log(response);
    //     this.router.navigate(['/dashboard']);
    //   });
    // });
  }
  login() {
   
    if (this.loginModel.username == '') {
   //  this.toastr.error("Email is required");
     return;
   }
   
   if (this.loginModel.password == '') {
     //this.toastr.error("Password is required");
     return;
   }
   console.log(this.loginModel);
   this.LoginService.login(this.loginModel).subscribe(response => {
    console.log(response);
    if(response.userDetails.token != ''){
      this.LoginService.setCurrentUser(response);
    this.router.navigate(['/dashboard']);
    } 
    
     //window.location.href="dashboard";   
   });
  }
  onClick() {
    
    if (this.password1 == 'password') {
      this.password1 = 'text';
      this.show = true;
    } else {
      this.password1 = 'password';
      this.show = false;
    }
  }

  signWithGoogle():void{console.log("googlesignin"); //for google sign in
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    
  }
}
