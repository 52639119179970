import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private apiBaseUrl:string;

  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }

   getAllCohort(page:any):Observable<any>{    
     let users =  this.getLoggedUser();
    console.log(users);
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.teams+'?loggedInUserId='+users.id+'&pageNo='+page+'&pageSize=25';    
    return this.http.get(url,{ headers });
  }

    getLoggedUser(){
      return this.sharedService.getLoggedInUser();
    }
}
