import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-member-details',
  standalone: true,
  imports: [NgIf],
  templateUrl: './member-details.component.html',
  styleUrl: './member-details.component.css'
})
export class MemberDetailsComponent {
  @Input() memberbasic:any;
  @Input() screentype:any;

  
}
