<div class="row mt-3" style="display: flex;">
    <div class="col-md-6 memberGrid">
        <div class="col-md-12">
            <img class="img" src="/assets/img/memberMsg.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3">
           {{memberbasic.email}}
        </div>
    </div>
    <div class="col-md-5 memberGrid ml-2 ">
        <div class="col-md-12">
            <img class="img" src="/assets/img/mobileIcon.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3" *ngIf="memberbasic.phoneNumber != null">
            {{memberbasic.phoneNumber}}
        </div>
        <div class="col-md-12 memberDetail mt-3" *ngIf="memberbasic.phoneNumber == null">
            No Data
        </div>
    </div>
</div>
<div class="row mt-3" style="display: flex;">
    <div class="col memberGrid">
        <div class="col-md-12">
            <img class="img" src="/assets/img/location.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3"  *ngIf="memberbasic.address != null">
            {{memberbasic.address.country}}
        </div>
        <div class="col-md-12 memberDetail mt-3"  *ngIf="memberbasic.address == null">
            No Data
        </div>
    </div>
    <div class="col memberGrid ml-2 ">
        <div class="col-md-12">
            <img class="img" src="/assets/img/userIcon.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3" *ngIf="memberbasic.team != null">
            {{memberbasic.team.teamName}}
        </div>
        <div class="col-md-12 memberDetail mt-3" *ngIf="memberbasic.team == null">
           No Data
        </div>
    </div>
</div>