<div *ngFor="let member of meeting;">
<mat-card class="col-md-12 mt-3">
    <mat-card-header>
        <app-member-basic-detail  [memberbasic]="member" [screentype] = "1"></app-member-basic-detail>
        <!-- <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
        <mat-card-title class="ml-3 meetTitle">Rock Lee</mat-card-title>
        <mat-card-subtitle class="ml-3 meetSubTitle">Age :  26</mat-card-subtitle> -->
        <div class="col-3 meetSubTitle">
            {{getDate(member.eventDate.split('T')[0])}} 
        </div>
        <div class="col-4 meetSubTitle">
           {{getTime(member.eventTime)}}
         </div>
      </mat-card-header>
   
    <mat-card-content class="ml-4 mt-3">
        <div class="col-md-10 ml-4">
            <div class="followUp">{{member.title}}</div>
            <p class="call">{{member.eventDescription}} </p>
        </div>
        <div class="col-md-10 ml-2 flex">   
            <div class="col-md-5 " >
                <p class="followUp">Level Of Care</p>
                <p class="call">L1</p>
            </div>

            <div class="col-md-6 ml-1 flex" style="float:right">
                <div class="col-md-9">
                    <img class="img1" src="/assets/img/bluecalender.png"/>
                </div>
                
                 <div class="col-md-12">
                   <a href="{{member.meetingLink}}" target="_blank"> <img class="img" src="/assets/img/bluejoin.png"/></a>
                </div>
                
            </div>
            
        </div>
      
    </mat-card-content>
  </mat-card>

  <!-- <mat-card class="col-md-12 mt-3">
    <mat-card-header>
        <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
        <mat-card-title class="ml-3 meetTitle">Rock Lee</mat-card-title>
        <mat-card-subtitle class="ml-3 meetSubTitle">Age :  26</mat-card-subtitle>
        <div class="col-3 meetSubTitle">
           15 May 2024
        </div>
        <div class="col-4 meetSubTitle">
            8:30 AM -9:30 AM
         </div>
      </mat-card-header>
   
    <mat-card-content>
        <div class="col-md-10 ml-5">
            <div class="followUp">Follow Up Visit</div>
            <p class="call">I am new to the program and I have enrolled.</p>
        </div>
        <div class="col-md-10 ml-4 flex">
            <div class="col-md-5 leftMargin" >
                <p class="followUp">Level Of Care</p>
                <p class="call">L1</p>
            </div>

            <div class="col-md-6 ml-1 flex" style="float:right">
                <div class="col-md-9">
                    <img class="img1" src="/assets/img/bluecalender.png"/>
                </div>
                
                 <div class="col-md-12">
                    <img class="img" src="/assets/img/bluejoin.png"/>
                </div>
                
            </div>
            
        </div>
      
    </mat-card-content>
  </mat-card> -->
</div>