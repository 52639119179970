import { Injectable } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ControllerApiPath } from './constant';
import { jwtDecode } from "jwt-decode";
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  private apiBaseUrl = environment.apiBaseUrl;
  userRole:any =[];
  userDetails:any=[];
  @Output() getLoggedIn: EventEmitter<boolean> = new EventEmitter(false);
  constructor(private http: HttpClient) { }

  getToken() {
    const token = localStorage.getItem('token') ?? "";
   
    return token?.replace(/['"]+/g, '');
  } 
    

  createAuthorizationHeader() {
    var token=this.getToken();
    const headers = new HttpHeaders({
     // 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      //'X-ApiAccess-Key': '87AF$C5648AE267A689D2A75CDD165~',
       'X-ApiAccess-Key': environment.apiKey
    });
      
    return headers;
   
  }
  baseUrl(){
    return this.apiBaseUrl;
  }
  getloggedUserRole(){
    this.userRole = localStorage.getItem('dhruvaUser');
    this.userRole=JSON.parse(this.userRole);
   // console.log(this.userRole);
    return this.userRole.role;
  }
  getUserById(usrId: string): Observable<any> {
    const headers = this.createAuthorizationHeader();
    let url = this.apiBaseUrl + ControllerApiPath.getUserById + '?userId=' + usrId;
    return this.http.get(url, { headers });    
   
  }
  checkLogin() {
    const loggedIn = (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '');
    if (!loggedIn) {
      var cookiesToken = this.getCookie("redirect-auth-token");
      if (cookiesToken !== "") {
        const decodedToken = jwtDecode<any>(cookiesToken);
        const userId = decodedToken.unique_name;
        localStorage.setItem('token', cookiesToken);
        const newToken = localStorage.getItem('token');
        this.getUserById(userId).subscribe(response => {
          console.log(response);
          if (response.result != '') {
            this.setCurrentUser(response.result);           
          }          
        });
        if (newToken != null) {
          return true;
        }
      }
    
    }
    return loggedIn;
  }
  setCurrentUser(userDetails: User) {     
    const user = userDetails;          
    localStorage.setItem('dhruvaUser', JSON.stringify(userDetails));      
  }
  logout() {
    localStorage.removeItem('dhruvaUser');
    this.getLoggedIn.emit(true);
  }

  getLoggedInUser(){
    this.userDetails = localStorage.getItem('dhruvaUser');
    this.userDetails=JSON.parse(this.userDetails);
    return this.userDetails;
  }
  getCookie(name: any): string {
    var nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
      }
    }
    return "";
  }
}
