import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MemberCardComponent } from '../member-card/member-card.component';
import { NgForOf } from '@angular/common';
import { DashboardService } from '../../dashboard/dashboard.service';
import { Input } from '@angular/core';

@Component({
  selector: 'app-critical-patients',
  standalone: true,
  imports: [MatCardModule,MemberCardComponent,NgForOf],
  templateUrl: './critical-patients.component.html',
  styleUrl: './critical-patients.component.css'
})
export class CriticalPatientsComponent {
  @Input() memberdata:any;

    memberList:any=[];
    basicDetails:any=[];

    constructor(private dashboard:DashboardService) {
    }
   
}
