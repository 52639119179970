<div class="row">
    <img style="width:6%" src="assets/img/backbtn.png" />
</div>
<app-basic-details [screentype] = "2"></app-basic-details>

<div class="row mt-5 ml-2">
    <div class="title">Result</div>
</div>

<div class="row mt-5 ml-1">
    <div class="col dt">
        26 Aug 2024, Monday
    </div>

    <div class="col textAlign ">
        
            <div class="mainTabDiv">
                <div class="tabContent">Add Fasting Window</div>
            </div>

            <div class="activeTab ml-1">
                <div class="tabContent" style="color:#000">Add Meal</div>
            </div>
       
    </div>
</div>

<div class="row mt-5 ">
    <div class="col-sm ">
       <app-result></app-result>
    </div>  

    <div class="col-sm  ">
       
        <app-result></app-result>  

    </div>  
</div>