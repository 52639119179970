<div id="content-page" class="content-page">
  <div class="container-fluid">
      
<div class="row">
  <div class="col-md-12 col-lg-6">
    <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div class="iq-card-body">
              <div class="title">Member List Overview</div>
                
            </div>

          
        </div>
     </div>
  
</div>
<div class="row mt-4 flex">
  <div class="col">
    <div class="iq-search-bar">
      <form action="#" class="searchbox">
         <input type="text" class="text search-input searchInner" (change)="filterData($event,1)" placeholder="Search Member By Name">
         <a class="search-link" href="javascript:void(0)" >
          <i class="fa-solid fa-magnifying-glass" style="color:#000"></i></a>
      </form>
   </div>
  </div>

  <div class="col">
    <div class="iq-search-bar">
      <select class="form-control" (change)="filterData($event,2)">
        <option value="none">none</option>
        <option value="byName">byName</option>
        <option value="pendingProfile">pendingProfile</option>
        <option value="onBoardingPending">onBoardingPending</option>
        <option value="questionaryPending">questionaryPending</option>
        <option value="labWorkPending">labWorkPending</option>
        <option value="callPending">callPending</option>
        <option value="completedProfile">completedProfile</option>
        <option value="onboardingCompleted">onboardingCompleted</option>
        <option value="questionaryCompleted">questionaryCompleted</option>
        <option value="labWorkCompleted">labWorkCompleted</option>
        <option value="callCompleted">callCompleted</option>
      </select>
   </div>
  </div>

  <div class="col">
    <div class="iq-search-bar">
      <select class="form-control" (change)="filterData($event,2)"  [(ngModel)]="this.filter" [ngModelOptions]="{standalone: true}" style="border-radius:20px;padding: 24px;height:0px !important;">
        <option value="0">Choose Option</option>
        <option value="0">Level 1</option>
        <option value="1">Level 2</option>
        <option value="2">Level 3</option>
        <option value="3">Level 4</option>
        <option value="4">Level 5</option>
      </select>
   </div>
  </div>

  <div class="col" style="margin-top:-5px;">
    <button mat-flat-button color="primary" class="color">Apply</button>
  </div>

 

  </div>

  <div class="row mt-5">
    
    <table mat-table [dataSource]="dataSource">

      <!-- Position Column -->
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef> No</th>
        <td mat-cell *matCellDef="let element;let i = index;"> {{i+1}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef> FirstName </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef> LastName </th>
        <td mat-cell *matCellDef="let element">{{element.lastName}}  </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}}</td>
      </ng-container>


      <ng-container matColumnDef="Mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.mobileNo}}</td>
      </ng-container>

      <ng-container matColumnDef="City">
        <th mat-header-cell *matHeaderCellDef> City </th>
        <td mat-cell *matCellDef="let element"> {{element.address.city}}</td>
      </ng-container>

      <ng-container matColumnDef="State">
        <th mat-header-cell *matHeaderCellDef> State </th>
        <td mat-cell *matCellDef="let element"> {{element.address.state}}</td>
      </ng-container>

     

      <ng-container matColumnDef="Team">
        <th mat-header-cell *matHeaderCellDef> Team </th>
        <td mat-cell *matCellDef="let element"> {{element.teamName}}</td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <td mat-header-cell *matHeaderCellDef>Actions</td>
        <td mat-cell *matCellDef="let row">
          <a [routerLink]="['/member-details']" [queryParams]="{ id:row.memberId,islist:1}" class="memberDashboard1" mat-button>View Details</a>
          <a [routerLink]="['/member-dashboard']" [queryParams]="{ memberId:row.memberId}" class="memberDashboard" mat-button>Member Dashboard</a>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    </table>

    <mat-paginator #paginator
    (page)="pageChangeEvent($event)"
    [length]="this.memberDetails['totalCount']"
    [pageSize]="10"
    [pageIndex]="1"
    [length]="this.memberDetails['totalCount']"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    showFirstLastButton>
  </mat-paginator>
    <!-- <div class="col-md-12 col-lg-6">
      <div><span class="levelTitle">Level of Care:</span><span class="levelTitle2"> L1</span></div>
    </div>  -->
  </div>
  <!-- <div class="row flex mt-4 align-items-start">
    
    <app-member-card class="col-4" *ngFor='let member of this.memberLists[0];let i=index' [memberdata]="member[i]" [screentype] = "1"></app-member-card>
      </div>

      <div class="row mt-5">
        <div class="col-md-12 col-lg-6">
          <div><span class="levelTitle">Level of Care:</span><span class="levelTitle2"> L3</span></div>
        </div>
      </div>
      <div class="row flex mt-4 align-items-start">
        
        <app-member-card class="col-4" *ngFor='let member of memberLists[1];' [memberdata]="member" [screentype] = "1"></app-member-card>
      </div>

        <div class="row mt-5">
            <div class="col-md-12 col-lg-6">
              <div><span class="levelTitle">Level of Care:</span><span class="levelTitle2"> L3</span></div>
            </div>
          </div>
          <div class="row flex mt-4 align-items-start">
            
            <app-member-card class="col-4" *ngFor='let member of memberLists[2];' [memberdata]="member" [screentype] = "1"></app-member-card>
          </div>


          <div class="row mt-5">
            <div class="col-md-12 col-lg-6">
              <div><span class="levelTitle">Level of Care:</span><span class="levelTitle2"> L4</span></div>
            </div>
          </div>
          <div class="row flex mt-4 align-items-start">
            
            <app-member-card class="col-4" *ngFor='let member of memberLists[3];' [memberdata]="member" [screentype] = "1"></app-member-card>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-6">
              <div><span class="levelTitle">Level of Care:</span><span class="levelTitle2"> L5</span></div>
            </div>
          </div>
          <div class="row flex mt-4 align-items-start">
            
            <app-member-card class="col-4" *ngFor='let member of memberLists[4];' [memberdata]="member" [screentype] = "1"></app-member-card>
          </div>

        </div>

  
  </div> -->