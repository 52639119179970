import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import { MemberBasicDetailComponent } from '../../member/member-card/member-basic-detail/member-basic-detail.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-lab-test',
  standalone: true,
  imports: [MatButton,MatCardModule,MatListModule,MemberBasicDetailComponent,RouterLink],
  templateUrl: './lab-test.component.html',
  styleUrl: './lab-test.component.css'
})
export class LabTestComponent {
  memberList:any=[
    {
      'title':'Test',
      'subTitle':'Diabetes Manager',
      'blood':'40',
      'glucose':'60',
      'meet':'https:google.com'
  },
  {
    'title':'Test 2',
      'subTitle':'Diabetes Manager',
      'blood':'40',
      'glucose':'60',
      'meet':'https:google.com'
  }
];
}
