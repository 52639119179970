<div class="mainTitle">Meal Planner</div>  

<app-meal-notification></app-meal-notification>

<div class="mainTitle mt-5">Meal Plan</div> 

<div class="col-md-12 mt-4 flex" style="padding: 0;">
    <div class="col-md-6">
    <mat-list role="list" style="display: flex;">
        <mat-list-item class="col-md-3 "  role="listitem" >
            <div class="active tab">
                <div class="tabContent">All Members</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-4"  role="listitem">
            <div class=" tab">
                <div class="tabContent">Without Meal Plan</div>
            </div>
        </mat-list-item>
        <mat-list-item class="col-md-5" role="listitem" >
            <div class=" tab">
                <div class="tabContent">Meal Plan expiring in 3 days</div>
            </div>
            </mat-list-item>
        
      </mat-list>
    </div>

    <div class="col-md-4 float-right textAlign mt-2">
        
         <input matInput type="textbox" placeholder="Search by member" class="form-control textField" />
    </div>
</div>

<!-- <div class="col-md-12 flex mt-5" style="padding:0">
    <app-member-meal-details class="col-md-6"></app-member-meal-details>
    <app-member-meal-details class="col-md-6"></app-member-meal-details>

    <app-member-meal-details class="col-md-6"></app-member-meal-details>
    <app-member-meal-details class="col-md-6"></app-member-meal-details>
</div> -->

<div class="row mt-5">
    <div class="col-sm">
        <app-member-meal-details ></app-member-meal-details>
    </div>
    
    <div class="col-sm">
        <app-member-meal-details ></app-member-meal-details>
    </div>
    
    <div class="col-sm">
        <app-member-meal-details ></app-member-meal-details>
    </div>
    
  
  </div>


  <div class="row mt-5">
    <div class="col-sm">
        <app-member-meal-details ></app-member-meal-details>
    </div>
    
    <div class="col-sm">
        <app-member-meal-details ></app-member-meal-details>
    </div>
    
    <div class="col-sm">
        <app-member-meal-details ></app-member-meal-details>
    </div>
    
  
  </div>
 
   
