
<mat-card class=" memberPartern mt-3">
    
    <mat-card-header>
      <mat-card-title class="">{{teamdata.teamName}}</mat-card-title>
      <mat-card-subtitle class="age">Team Level: {{teamdata.teamType}} people</mat-card-subtitle> 
    </mat-card-header>
     
    <mat-card-content>
       <app-team-details [teamdata1]="teamdata" [screentype] = "1"></app-team-details>
    </mat-card-content>
    <mat-card-actions class="mt-4  ">
        <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/deleteIcon.png" /></a>
      
        <button class="memberDashboard1" mat-button>Assign Member</button>
        <button class="memberDashboard1" mat-button>View Members</button>
        <button class="memberDashboard" mat-button>View Details</button>
    </mat-card-actions>
  </mat-card>
