<div class="maintitle mt-3 ml-2">Upload File</div>
<div class="subTitle mt-3 ml-2">Upload your recent blood report or prescription to validate your overall health, This could help us in many ways.</div>

<div class="col-md-12" style="display: flex;">
    <div class="col-md-6 uploadBox float mt-4">
        <div >
            <img src="/assets/img/upload.png"/>
        </div>
        <div class="uploadContent mt-2">Upload your recent report</div>
       
            <button class="btnCl mt-3">Click here to upload</button>
        

    </div>

    <div class="col-md-6 uploadBox float mt-4 ml-5">
        <div >
            <img src="/assets/img/upload.png"/>
        </div>
        <div class="uploadContent mt-2">Upload your recent prescription</div>
       
            <button class="btnCl mt-3">Click here to upload</button>
        

    </div>
</div>
<div class="col-md-12 mt-2">
    <div class="question mt-5">1. Do you have any family history of chronic disease, such as heart disease or diabetes, or health conditions such as high blood pressure or high cholesterol?</div>
    <div class="col-md-7">
        <select class="form-control mt-3 select">
            <option value="Grant Parents">Grant Parents</option>
        </select>
        <select class="form-control mt-3 select">
            <option value="Grant Parents">Mother</option>
        </select>
        <select class="form-control mt-3 select">
            <option value="Grant Parents">Father</option>
        </select>
        <select class="form-control mt-3 select">
            <option value="Grant Parents">Siblings</option>
        </select>
    </div>
  
</div>

<div class="col-md-12">
    <div class="col-md-6 float mt-5">
        <div class="question ">2. History of diabetes:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>
    <div class="col-md-6 float mt-5">
        <div class="question ">3. Vision</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">4. Last HbA1c Value:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">5. Conditions:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">6. Any food allergy:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">7. Any gut problem:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">8. Are you prone to UTI:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">9. Any surgery taken:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>


    <div class="col-md-6 float mt-5">
        <div class="question ">10. Are you prone to any infection:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">11. Are you suffering from any pain:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">12. Easily digest milk products:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">13. Are you taking any thyroid meds?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">14. Do you have any Co-morbated conditions?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">15. Are you currently taking any other medications?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-6 float mt-5">
        <div class="question ">16. Are you taking any other supplements?</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here..."></textarea>
    </div>

    <div class="col-md-12 float mt-5">
        <button type="submit" class="btn btnClass">Save</button>
     </div>
     
</div>