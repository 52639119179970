<div class="memberPartern">
<app-basic-details [screentype] = "1"></app-basic-details>

<div class="row mt-4">
    <div class="col-sm">
        <div class="col-md-12 labels">
            Meals per day
        </div>
        <div class="col-md-12 mt-2 labelContent">
           4 Meals
        </div>

    </div>

    <div class="col-sm">
        <div class="col-md-12 labels">
            Due Date

        </div>
        <div class="col-md-12 mt-2 labelContent">
            20 Aug 2024
        </div>

    </div>

    <div class="col-sm">
        <div class="col-md-12 labels">
            Left Meals
        </div>
        <div class="col-md-12 mt-2 labelContent">
           14
        </div>

    </div>
</div>
<div class="row mt-4">
    <div class="col-sm">
        <button class="btn btnFirst btnLabel">Create Meal Plan</button>

    </div>

    <div class="col-sm">
        <button class="btn btnSec btnLabel ml-2">View Meal Plan</button>

    </div>
</div>
</div>