import { Component } from '@angular/core';
import { MemberInfoComponent } from './member-info/member-info.component';
import { ScoreComponent } from './score/score.component';
import { LabReportsComponent } from './lab-reports/lab-reports.component';
import { GulcoseComponent } from './gulcose/gulcose.component';
import { LifeStyleComponent } from './life-style/life-style.component';
import { SleepComponent } from './sleep/sleep.component';
import { ActivityComponent } from './activity/activity.component';
import { MemberDashboardService } from './member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-member-dashboard',
  standalone: true,
  imports: [MemberInfoComponent,ScoreComponent,LabReportsComponent,GulcoseComponent,LifeStyleComponent,SleepComponent,ActivityComponent],
  templateUrl: './member-dashboard.component.html',
  styleUrl: './member-dashboard.component.css'
})
export class MemberDashboardComponent {
//   memberList:any=[
//     {
//     'title':'Test',
//     'subTitle':'Diabetes Manage',
//     'dob':'13/11/1995',
//     'height':'155 cm',
//     'weight':'59 KG',
//     'level':'L1',
//     'bmi':'20.9 Kg/m'
//   },
// ];

memberList:any=[];
scores:any=[];
labList:any=[];
memberId:any=this.route.snapshot.queryParamMap.get('memberId');
vitalDetails:any;
constructor(private dashboard:MemberDashboardService,private route:ActivatedRoute) {
}

ngOnInit(): void {
 
  this.getmemberById();
  this.getMemberScore(formatDate(new Date(), 'Y-MM-d', 'en'));
  this.getLabWork();
  this.getVitalData('2024-09-03');
}
getmemberById(){
  this.dashboard.getMemberById(this.memberId).subscribe(response => {    
      this.memberList = response;
      this.memberList = this.memberList.result;
      console.log(this.memberList);
  });
}

getMemberScore(data:any){
  console.log(data);
  this.dashboard.getMemberScore(this.memberId,data).subscribe(response => {    
    this.scores = response;
    this.scores = this.scores.result;
   
    console.log(this.scores);
});
}

getLabWork(){
  this.dashboard.getLabWork(this.memberId).subscribe(response => {    
    this.labList = response;
    this.labList = this.labList.result;
   
    console.log(this.labList);
});
}

getVitalData(data:any){
  this.dashboard.getVital(this.memberId,data).subscribe(response => {    
    this.vitalDetails = response;
    this.vitalDetails = this.vitalDetails.result;
   
    console.log(this.vitalDetails);
});
}

}
