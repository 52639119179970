import { Component } from '@angular/core';
import { NotesComponent } from '../life-style/notes/notes.component';

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [NotesComponent],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.css'
})
export class ActivityComponent {

}
