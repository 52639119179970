<div class="row mt-3" style="display: flex;">
    <!-- <div class="col memberGrid">
        <div class="col-md-12">
            <img class="img" src="/assets/img/singleUser.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3">
            kevin jerrald
        </div>
    </div> -->
    <div class="col memberGrid ml-2 ">
        <div class="col-md-12">
            <img class="img" src="/assets/img/typeicon.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3" *ngIf="teamdata1.diabetesType != null">
           {{teamdata1.diabetesType}}
        </div>

        <div class="col-md-12 memberDetail mt-3" *ngIf="teamdata1.diabetesType == null">
            No Data
         </div>
    </div>
</div>
<div class="row mt-3" style="display: flex;">
    <div class="col memberGrid">
        <div class="col-md-12">
            <img class="img" src="/assets/img/location.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3" *ngIf="teamdata1.teamAddress != null">
            {{teamdata1.teamAddress.country}}
        </div>

        <div class="col-md-12 memberDetail mt-3" *ngIf="teamdata1.teamAddress == null">
           No Data
        </div>
    </div>
    <div class="col memberGrid ml-2 ">
        <div class="col-md-12">
            <img class="img" src="/assets/img/calIcon.png"/>
        </div>
        <div class="col-md-12 memberDetail mt-3">
            {{teamdata1.createdDateTime.split('T')[0]}}
        </div>
    </div>
</div>