import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { QuestionOptionsComponent } from '../personality/question-options/question-options.component';

@Component({
  selector: 'app-dietary',
  standalone: true,
  imports: [MatListModule,QuestionOptionsComponent],
  templateUrl: './dietary.component.html',
  styleUrl: './dietary.component.css'
})
export class DietaryComponent {

}
