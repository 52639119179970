<div class="col-md-12 flex" *ngIf="screentype  == 1">
    
    <div class="col-lg-3"><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
    <div class="col-lg-6 name mt-1" >
      <div class="col-md-12 name">
         Kevin
      </div>
      <div class="col-md-12 age" >
        Age :26
    </div>
    </div>

    <div class="col-md-3 flex" style="margin-right:5%">
        <div class="col-md-3"><img src="assets/img/editBtn.png" /></div> 
        <div class="col-md-3 mt-1">Edit</div>
        
    </div>
</div>

<div class="row mt-5" *ngIf="screentype  == 2">
    <div class="col-3 flex">
        <div class="col-lg-3"><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
    <div class="col-lg-6 name mt-1" >
      <div class="col-md-12 name">
         Kevin
      </div>
      <div class="col-md-12 age" >
        Age :26
    </div>
    </div>
    </div>
    
    <div class="col-2 borderClass">
       <div class="col-md-12 name">
        Actual Weight
       </div>
       <div class="col-md-12 age" >
        60 kg
    </div>
    </div>
    
    <div class="col-2">
        <div class="col-md-12 name">
            Target Weight
           </div>
           <div class="col-md-12 age" >
            50 kg
        </div>
    </div>
    
  
  </div>
 