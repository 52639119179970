import { Component } from '@angular/core';

@Component({
  selector: 'app-life-style',
  standalone: true,
  imports: [],
  templateUrl: './life-style.component.html',
  styleUrl: './life-style.component.css'
})
export class LifeStyleComponent {

}
