import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-team-details',
  standalone: true,
  imports: [NgIf],
  templateUrl: './team-details.component.html',
  styleUrl: './team-details.component.css'
})
export class TeamDetailsComponent {
  @Input() teamdata1:any;
  @Input() screentype:any;
}
