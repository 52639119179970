import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TeamDetailsComponent } from '../team-details/team-details.component';
@Component({
  selector: 'app-team-card',
  standalone: true,
  imports: [MatCardModule,TeamDetailsComponent],
  templateUrl: './team-card.component.html',
  styleUrl: './team-card.component.css'
})
export class TeamCardComponent {
  @Input() teamdata:any;
  @Input() screentype:any;
  
}
