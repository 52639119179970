
    <div class="row mt-5" style="display:flex">
    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/sleep.png"/>
        </div>
        <div class="col-sm float scoreContent">
            <p class="scoreContent">Sleep Score</p>
            <p class="scoreNo"><span *ngIf="scores.sleepData == null">0</span> <span *ngIf="scores.sleepData != null">{{scores.sleepData}}</span></p>
        </div>
    </div>

    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/nutri.png"/>
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Nutrition Score</p>
            <p class="scoreNo"><span *ngIf="scores.nutritionData == null">0</span> <span *ngIf="scores.nutritionData != null">{{scores.nutritionData}}</span></p>
        </div>
    </div>


    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/activity.png"/>
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Activity Score</p>
            <p class="scoreNo"><span *ngIf="scores.activityData == null">0</span> <span *ngIf="scores.activityData != null">{{scores.activityData}}</span></p>
        </div>
    </div>

    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/glucose.png"/>
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Glucose Score</p>
            <p class="scoreNo"><span *ngIf="scores.sleepData == null">0</span> <span *ngIf="scores.glycemicData != null">{{scores.glycemicData}}</span></p>
        </div>
    </div>

    <!-- <div class="col scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/glucose.png"/>
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Focus Score</p>
            <p class="scoreNo">98</p>
        </div>
    </div> -->
</div>
