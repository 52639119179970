import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MemberBasicDetailComponent } from '../../member-card/member-basic-detail/member-basic-detail.component';
import { Input } from '@angular/core';
import { MemberDetailsComponent } from '../member-details/member-details.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-member-card',
  standalone: true,
  imports: [MatCardModule,MemberBasicDetailComponent,MemberDetailsComponent,RouterLink],
  templateUrl: './member-card.component.html',
  styleUrl: './member-card.component.css'
})
export class MemberCardComponent {
  @Input() memberdata:any;
  @Input() screentype:any;
}
