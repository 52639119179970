<div class="row flex fontfamily" >
    <div class="col-md-6">
        <app-common-image></app-common-image>
    </div>
    <div class="col-md-5 ml-3 marginTop">
        <div class="col-md-12 welcomeMsg">
            Welcome Doctor
        </div>

        <div class="col-md-12 mt-2 subTitle">
           Sign in to get the overview of your dashboard
        </div>

        <div class="col-md-12 mt-4">
            <form #loginForm="ngForm" (ngSubmit)="login()" >
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label labelReport">Email Address</label>

                  <input matInput type="email"  [(ngModel)]="loginModel.username" [ngModelOptions]="{standalone: true}" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$" #email="ngModel" required  placeholder="Enter Email Address" class="form-control inputField" id="exampleInputEmail1" aria-describedby="emailHelp">
                 
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label labelReport">Password</label>
                  <input [type]="password1"  [(ngModel)]="loginModel.password" [ngModelOptions]="{standalone: true}" #password="ngModel" required placeholder="Enter Password" class="form-control inputField" id="exampleInputPassword1">
                  <button type="button" class="eyeClass" (click)="onClick()">
                    <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                  </button>
                </div>
                <div class="mb-4 form-check">
                  <input matInput type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label remember" for="exampleCheck1">Remember Me</label>
                  <a  [routerLink]="['forgot-password']"  class=" remember" for="exampleCheck1" style="float:right">Forgot Password?</a>
                </div>
                
                
                <button type="submit" class="btn btn-primary btn mt-4">Submit</button>
              </form>
        </div>
        <div class="col-md-12 mt-3 or">
           OR

          
        </div>
        <div class="col-md-12 mt-3">
          <asl-google-signin-button 
            size='large'></asl-google-signin-button>
        </div>
    </div>
</div>