<div class="col-md-12 flex">
    <div class="col-md-9 float">
        <div class="col-md-7 mainSleep float">
            
                <div class="col-md-12 ml-3">
                        <img src="/assets/img/activityCount.png"/>
                </div>
                <!-- <div class="col-md-12">
                    <div class="col-md-12 mt-3">Total Steps</div>
                    <div class="col-md-12 mt-3">8,567</div>
                    <div class="col-md-12 mt-3">
                        <button class="btn btn-success">Excellent</button>
                    </div>
                </div> -->
                <div class="col-md-12 mt-5">
                   <p class="activityContent">Need a little more steps</p>
                   <p class="mt-4 activityContent1">Kevin is going to complete the steps goal, Cheer him to complete the goal as soon as possible</p>
                </div>

        </div>
        <div class="col-md-5 float ">
            <div class="col-md-12 float sleepSub ">
                <p><img class="img" src="/assets/img/walk.png"/></p>
                <p class="totalBurn">Total kilometers Walked</p>
                <p class="totalCount"><b>8.3 KM</b></p>
                <p><button class="btn btn-success">Excellent</button></p>
            </div>

            <div class="col-md-12 float sleepSub ml-1 mt-3">
                <p><img class="img" src="/assets/img/heat.png"/></p>
                <p class="totalBurn">Total Calories Burned</p>
                <p class="totalCount">2,783</p>
                <p><button class="btn btn-success">Excellent</button></p>
            </div>
            
            
        </div>
    </div>
    <!-- <div class="col-md-4 float" style="margin-top:-70px">
        <div class="noteSection">Notes Section</div>
        <app-notes></app-notes>
    </div> -->
    
    </div>