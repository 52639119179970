import { Component } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';

import { RouterModule } from '@angular/router';
import { SharedServiceService } from '../services/shared-service.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-top-nav',
  standalone: true,
  imports: [MatMenuModule,MatIconModule,RouterModule],
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.css'
})
export class TopNavComponent {
  userDetails:any=[];
  oldDhruva = environment.oldDhruva;
  constructor(private sharedService:SharedServiceService){

  }

  ngOnInit(): void {
    this.userDetails = this.sharedService.getLoggedInUser();
    console.log(this.userDetails);
  }
  logout() {
    this.sharedService.logout();
   
    sessionStorage.clear();
    
    localStorage.setItem('dhruvaUser','');
    localStorage.setItem('token','');
    localStorage.setItem('auth-token','');
    
    window.location.href="/";
 
  }
}
