import { Component } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-member-detail-form',
  standalone: true,
  imports: [MatInputModule,MatFormFieldModule],
  templateUrl: './member-detail-form.component.html',
  styleUrl: './member-detail-form.component.css'
})
export class MemberDetailFormComponent {

}
