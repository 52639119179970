import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-total-nutri',
  standalone: true,
  imports: [],
  templateUrl: './total-nutri.component.html',
  styleUrl: './total-nutri.component.css'
})
export class TotalNutriComponent {
  
  @Input() foodInfo:any;

  
}
