import { Component } from '@angular/core';
import { CommonImageComponent } from '../login/common-image/common-image.component';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonImageComponent,RouterLink,FormsModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css'
})
export class ResetPasswordComponent {
  password:any='';
  confirmPassword:any='';
  constructor(private LoginService:LoginService) {
  }

  changePassword(){
    if(this.password == ''){
      return;
    }
    else if(this.confirmPassword == ''){
      return;
    }
    else if(this.confirmPassword != this.password ){
      return;
    }else{
      this.LoginService.checkEmailId(this.confirmPassword).subscribe(response => {
        window.location.href="";   
      });
    }
  }
}
