<div class="row mt-4">
    <app-member-info [memberbasic]="memberList" [vital]="vitalDetails" ></app-member-info>
   
</div>

<app-score [scores]="scores"></app-score>

<div class="row mt-5">
    <div class="labReport">Recent Lab Reports</div>
</div>

<!-- <div class="row mt-5 ">
    <app-lab-reports></app-lab-reports>
</div> -->
<app-lab-reports></app-lab-reports>

<div class="row mt-5">
    <div class="col-md-12 labReport">Glucose Mapping</div>
    <div class="col-md-12 gulcoseSub mt-3">Tue, 06 Sep</div>
</div>

<div class="row mt-3 ">
    <app-gulcose style="width:100%"></app-gulcose>
</div>


<div class="row mt-5">
    <div class="col-md-12 labReport">Lifestyle Habits</div>
    <div class="col-md-12 gulcoseSub mt-3">Tue, 06 Sep - Mon, 13 Sep</div>
</div>

<div class="row mt-5">
    <div class="col-md-12 labReport">Nutrition</div>
    <div class="col-md-12 gulcoseSub mt-3">Calories and Macros</div>
</div>

<div class="row mt-3">
    <app-life-style class="fullWidth"></app-life-style>
</div>

<div class="row mt-5">
    <div class="col-md-12 labReport">Sleep</div>


    <div class="col-md-12 gulcoseSub mt-3">Daily Sleep Data</div>
</div>

<div class="row mt-3">
    <app-sleep class="fullWidth"></app-sleep>
</div>

<div class="row mt-5">
    <div class="col-md-12 labReport">Activity</div>


    <div class="col-md-12 gulcoseSub mt-3">Movement Data</div>
</div>

<div class="row mt-3">
    <app-activity class="fullWidth"></app-activity>
</div>