<div class="col-md-12 heading mt-2">Medication</div>

<div class="col-md-12 mainDiv mt-2">
    <div class="col-md-12 question">1. What kind of medications are you in?</div>
    <div class="col-md-12 mt-3 flex">
        <div class="col">
            <div class="medicenTitle">Insulin</div>
            <div class="col-md-12 mt-4">
                <div class="medicen mt-2">
                    <div class="name">Levemir (Determir 100 units/ml)</div>
                    <div class="cross">
                       X
                    </div>
                </div>

                 <div class="medicen mt-2">
                    <div class="name">Glitazone</div>
                    <div class="cross">
                       X
                    </div>
                </div>
                <div class="medicen mt-2">
                    <div class="name">Pioglitazone</div>
                    <div class="cross">
                       X
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="medicenTitle">Sulfonylureas</div>
            <div class="col-md-12 mt-4"> 
                <div class="medicen mt-2">
                    <div class="name">Levemir (Determir 100 units/ml)</div>
                    <div class="cross">
                       X
                    </div>
                </div>

                 <div class="medicen mt-2">
                    <div class="name">Glitazone</div>
                    <div class="cross">
                       X
                    </div>
                </div>
                <div class="medicen mt-2">
                    <div class="name">Pioglitazone</div>
                    <div class="cross">
                       X
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="medicenTitle">Insulin</div>
            <div class="col-md-12 mt-4">
                <div class="medicen mt-2">
                    <div class="name">Glimepiride</div>
                    <div class="cross">
                       X
                    </div>
                </div>

                 <div class="medicen mt-2">
                    <div class="name">Glipizide</div>
                    <div class="cross">
                       X
                    </div>
                </div>
                <div class="medicen mt-2">
                    <div class="name">Pioglitazone</div>
                    <div class="cross">
                       X
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
</div>

<div class="col-md-12 heading mt-4">Other Metabolic Conditions</div>
<div class="col-md-12 mainDiv mt-2">
    <div class="col-md-12 question">1. Do you have any other medical conditions?</div>
    <div class="col-md-12 mt-3 flex">
        <div class="col">
            <div class="medi">Hypertension</div>
        </div>

        <div class="col">
            <div class="medi">Hypertension</div>
        </div>

        <div class="col">
            <div class="medi">Hypertension</div>
        </div>

        <div class="col">
            <div class="medi">Hypertension</div>
        </div>
    </div>

    <div class="col-md-12 mt-5">
        <textarea class="form-control textarea" row="20"></textarea>
    </div>
</div>


<div class="col-md-12 heading mt-4">Monitoring Frequency</div>
<div class="col-md-12 mainDiv mt-2">
    <div class="col-md-12 question">1. Does any of your family members experience the following?</div>
    <div class="col-md-12 mt-3 flex">
        <div class="col-md-4">
            <div class="medi">Depression, anxiety or migranes</div>
        </div>
      
    </div>

</div>

<div class="col-md-12 heading mt-4">Surgery</div>
<div class="col-md-12 mainDiv mt-2">
    <div class="col-md-12 question">1. If you have undregone anu surgery, Please mention below:-</div>


    <div class="col-md-12 mt-5">
        <textarea class="form-control textarea" col="20" ></textarea>
    </div>
</div>