<div class="col-md-12 mt-4">
    <div class="mainDiv">
        <div class="title mt-5">General Information</div>
        <div class="mt-5"><span class="visitDt">Date of visit: </span><span class="visitDt1">16 May 2024 & 9:00 AM (IST)</span></div>
        <div class="mt-5"><span class="visitType">Type of visit: </span><span class="followUp">Follow-Up</span></div>
        <div class="subject mt-5">Subjective</div>
        <div class="col-md-12">
            <div class="label mt-5">Chief Complaint:</div>
            <textarea class="textArea mt-4" placeholder="Type Here...."></textarea>
        </div>
        <div class="col-md-12 mt-5">
            <div class="label">Symptoms:</div>
            <textarea class="textArea mt-4" placeholder="Type Here...."></textarea>
        </div>
        <div class="col-md-12 mt-4">
            <div class="label ">Previous Medical History:</div>
           <div class="mt-4">
            <button class="btn btnVal">Save</button>
           
           </div>
        </div>
    </div>
</div>
