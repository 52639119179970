import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-member-meal-details',
  standalone: true,
  imports: [BasicDetailsComponent,MatCardModule],
  templateUrl: './member-meal-details.component.html',
  styleUrl: './member-meal-details.component.css'
})
export class MemberMealDetailsComponent {

}
