import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgForOf } from '@angular/common';
import { MemberCardComponent } from './member-card/member-card.component';
import { MemberService } from './member.service';
import { SharedServiceService } from '../../services/shared-service.service';
import { Member } from '../../models/member';
import { FormsModule } from '@angular/forms';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-member-list',
  standalone: true,
  imports: [MatCardModule,NgForOf,MemberCardComponent,FormsModule,MatTableModule,MatPaginatorModule,MatPaginator,RouterLink],
  templateUrl: './member-list.component.html',
  styleUrl: './member-list.component.css'
})
export class MemberListComponent {
  displayedColumns: string[] = ['No', 'FirstName', 'LastName', 'Email','Mobile','City','State','Team','Actions'];
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

filter:any={
  'filterType':0,
  'name':'',
  'fromDate':new Date(),
  'toDate':new Date(),
  'userLoggedIn':'',
  'isAssigned':''
};

isAdmin:any;
role:any;
memberLists:any = [];
totalRecords:any=[];
memberDetails:any=[];

 paginator: MatPaginator;
 dataSource = new MatTableDataSource<Member>;
constructor(private MemberService:MemberService,private SharedService:SharedServiceService) {
  this.role = this.SharedService.getloggedUserRole();
  let user= this.SharedService.getLoggedInUser();
  this.filter.userLoggedIn = user.id
   
    if(this.role == 'Administrator'){
      this.isAdmin = true; 
    }
    else if(this.role != 'Administrator'){
      this.isAdmin = false;
    }

}
ngOnInit(): void {
 
  
 // this.dataSource.paginator = this.paginator;
  
  // let level:any=['memberLevel1','memberLevel2','memberLevel3','memberLevel4','memberLevel5'];
  // for(let i=0;i<5;i++){
  //   this.getMemberRecord(1,level[i],i);
  // }
  this.getMemberRecord(1);
}



getMemberRecord(page:any){
  this.MemberService.getMemberByFilterAPI(this.filter,page,this.isAdmin).subscribe(response => { 
    this.memberDetails = response;     
   // this.memberLists    =  this.memberDetails['memberTeamAssignment']; 
   // this.totalRecords  =  this.memberDetails['totalCount'];
    //const ELEMENT_DATA: Member[]=this.memberDetails['result'];
    this.dataSource = this.memberDetails['result'];
    this.dataSource.paginator  = this.memberDetails['totalCount'];
    console.log(this.memberDetails['totalCount']);
   // this.memberDetails =  this.memberDetails['memberTeamAssignment'];
    console.log(this.dataSource );
    
  });
 }

getMemberRecord1(page:any,level:any,index:any){
  let user= this.SharedService.getLoggedInUser();

  
 
  this.MemberService.getMemberByLevel(user.id,page,level,this.isAdmin ).subscribe(response => { 
   
    this.memberDetails = response;  
    let memberData:any=[];   
    if(this.memberDetails['result'].length > 0){
    for(let j=0;j<this.memberDetails['result'].length;j++){
      memberData[j]=[{
        'id':this.memberDetails['result'][j].memberId,
        'personName':this.memberDetails['result'][j].firstName+' '+this.memberDetails['result'][j].lastName,
        'subTitle':'',
        'age':this.memberDetails['result'][j].age,
        'email':this.memberDetails['result'][j].email,
        'phoneNumber':this.memberDetails['result'][j].mobileNo,
        'address':this.memberDetails['result'][j].address,
        'team':                                                                                                                                                                ''

    }];
    }
  }
    this.memberLists[index] = memberData; 
    this.totalRecords[index] = this.memberDetails['totalCount'];
   // this.memberDetails =  this.memberDetails['memberTeamAssignment'];
    
  });

console.log(this.memberLists );
 }

 filterData(data:any,type:any){
  //let level:any=['memberLevel1','memberLevel2','memberLevel3','memberLevel4','memberLevel5'];
  //this.getMemberRecord(1,level[data.target.value],data.target.value);
  if(type == 1){
    this.filter.name=data.target.value;
  }
  else if(type == 2){
    this.filter.filterType=data.target.value;
  }
  this.getMemberRecord(1);
 }

 pageChangeEvent(page:any){
  console.log(page.pageIndex);
  this.getMemberRecord(page.pageIndex);
 }
}
