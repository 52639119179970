<div class="col-md-12 heading mt-2">Records of Diabetes</div>

<div class="col-md-6 mainDiv mt-2 float">
    <div class="col-md-12 question">1. When were you diagnosed with diabetes or pre-diabetes?</div>
    <div class="medicen mt-4">
        <div class="name">Glimepiride</div>
        <div class="cross">
           
        </div>
    </div>

    <div class="col-md-12 mt-5">

        <div class="btnClass">
            <button class="btn btnDiv">Edit</button>
        </div>

    </div>
    </div>

    <div class="col-md-6 mainDiv mt-2 float">
        <div class="col-md-12 question">2. Date of Diagnosis</div>
        <div class="col-md-8 mt-4">
            
            <input type="text" class="form-control textField"/>
        </div>
    
        <div class="col-md-12 mt-5">
    
            <div class="btnClass">
                <button class="btn btnDiv">Edit</button>
            </div>
    
        </div>
        </div>

        <div class="col-md-12 heading mt-5 float">Glucose Value</div>
        <div class="col-md-6 mainDiv mt-3 float">
            <div class="col-md-12 question">1. What is latest HbA1c Value?</div>
            <div class="col-md-8 mt-4">
                
                <input type="text" class="form-control textField" value="156 mg/dl"/>
            </div>
        
            <div class="col-md-12 mt-5">
        
                <div class="btnClass">
                    <button class="btn btnDiv">Edit</button>
                </div>
        
            </div>
            </div>

            <div class="col-md-12 heading mt-5 float">Fasting Sugar Range</div>
            <div class="col-md-7 mainDiv mt-3 float">
                <div class="col-md-12 question">1. Please share your fasting sugar range limits (lower and higher)
                    .    in mg/dl:</div>
                <div class="col-md-5 mt-4 float">
                    <label>Lower</label>
                    <input type="text" class="form-control textField" value="156 mg/dl"/>
                </div>
                <div class="col-md-5 mt-4 float">
                    <label>Higer</label>
                    <input type="text" class="form-control textField" value="156 mg/dl"/>
                </div>
            
                <div class="col-md-12 mt-5 float">
            
                    <div class="btnClass">
                        <button class="btn btnDiv">Edit</button>
                    </div>
            
                </div>
                </div>

                <div class="col-md-12 heading mt-5 float">Post Meal Range</div>
                <div class="col-md-7 mainDiv mt-3 float">
                    <div class="col-md-12 question">1. Please share your post meal range limits (lower and higher) in mg/dl</div>
                    <div class="col-md-5 mt-4 float">
                        <label>Lower</label>
                        <input type="text" class="form-control textField" value="156 mg/dl"/>
                    </div>
                    <div class="col-md-5 mt-4 float">
                        <label>Higer</label>
                        <input type="text" class="form-control textField" value="156 mg/dl"/>
                    </div>
                
                    <div class="col-md-12 mt-5 float">
                
                        <div class="btnClass">
                            <button class="btn btnDiv">Edit</button>
                        </div>
                
                    </div>
                    </div>


                    <div class="col-md-12 heading mt-5 float">Symptoms and frequency of low blood sugar</div>
                    <div class="col-md-7 mainDiv mt-3 float">
                        <div class="col-md-12 question">1. How often do you experience low blood sugar (excessive sweating, hunger pangs, palpitations, confused state of mind)?</div>
                        <div class="medicen mt-4">
                            <div class="name">Glimepiride</div>
                            <div class="cross">
                               
                            </div>
                        </div>
                    
                        <div class="col-md-12 mt-5 float">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                        </div>


                        

                    <div class="col-md-12 heading mt-5 float">Monitoring of blood glucose</div>
                    <div class="col-md-7 mainDiv mt-3 float">
                        <div class="col-md-12 question">1. How often do you check your blood glucose levels?</div>
                        <div class="medicen mt-4">
                            <div class="name">More than 2 times per week</div>
                            <div class="cross">
                               
                            </div>
                        </div>
                    
                        <div class="col-md-12 mt-5 float">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                        </div>

                        

                    <div class="col-md-12 heading mt-5 float">Assistance</div>
                    <div class="col-md-7 mainDiv mt-3 float">
                        <div class="col-md-12 question">1. Have you ever needed assistance from another person doing low blood sugar?</div>
                        <div class="medicen mt-4">
                            <div class="name">I need assistance</div>
                            <div class="cross">
                               
                            </div>
                        </div>
                    
                        <div class="col-md-12 mt-5 float">
                    
                            <div class="btnClass">
                                <button class="btn btnDiv">Edit</button>
                            </div>
                    
                        </div>
                        </div>