import { Component } from '@angular/core';
import { TeamCardComponent } from './team-card/team-card.component';
import { NgForOf } from '@angular/common';
import { TeamService } from './team.service';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-team-listing',
  standalone: true,
  imports: [TeamCardComponent,NgForOf,MatPaginatorModule],
  templateUrl: './team-listing.component.html',
  styleUrl: './team-listing.component.css'
})
export class TeamListingComponent {
  totalRecords:number;
  teamList:any=[];
  teamDetails:any=[];
  page=1;
  pageNumber:any;
 // pageSize=25;
constructor(private teamService:TeamService) {
}
ngOnInit(): void {
  this.getTeams(1);
}

getNextPage(id:any){
 // console.log(id);
  let pageNumber = id.pageIndex + 1;
  this.getTeams(pageNumber);
  
}

getTeams(page:any){
  this.teamService.getAllCohort(page).subscribe(response => {    
    this.teamDetails = response; 
    this.totalRecords = this.teamDetails['totalCount'];
    this.teamList = this.teamDetails['result'];
    console.log(this.teamList);
  });

}

}
