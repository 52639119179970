

export class ControllerApiPath{
    public static readonly inviteUser = 'identity/inviteuser';
    public static readonly acceptInvite = 'identity/acceptinvite?key=';
    public static readonly updatePassword = 'identity/updatepassword/';
    public static readonly login = 'account/login';
    public static readonly identity = 'identity';
    public static readonly members = 'members';
    public static readonly teams = 'teams';
    public static readonly foodlog = 'foodLogs';
    public static readonly member = 'member';
    public static readonly scores = 'scores';
    public static readonly events = 'events';
    public static readonly getUserById = 'account/GetUserById';
    public static readonly labs = 'LabWork';
    public static readonly vital = 'vitals';
    public static readonly healthData = 'healthData';
}

export class StatesConstant{
    static readonly userState = 'userState';
}


