import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-score',
  standalone: true,
  imports: [NgIf],
  templateUrl: './score.component.html',
  styleUrl: './score.component.css'
})
export class ScoreComponent {
  @Input() scores:any;
}
